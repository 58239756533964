/* html5 elements ***************************************************** */
body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    -webkit-font-smoothing: initial;
    -moz-osx-font-smoothing: initial;
    & + p {
    	margin-top: 1em;
    }
}
@include media-breakpoint-up(lg) { // desktop styles
	h1 {
		font-size: $font-size-h1-desktop;
	}
	h2 {
		font-size: $font-size-h2-desktop;
	}
}

/* bootstrap classes **************************************************** */

.row {
	min-width: 100%;
}
.btn {
	border-width: 1px; 
	.bg-green &.btn-primary, .bg-green &.btn-default,
	&.btn-outline-primary, &.btn-outline-secondary {
		border-width: 3px;
	}
	border-radius: .25rem;
	align-self: left;
	min-width: 200px;
	&:hover {
		color: inherit;
	}
	.input-group & {
		min-width: 0;
	}
}
.embed-responsive {
	max-width: 100%;
	width: auto;
	height: auto;
}
.footer {
	background: $black;
	color: $white;
	padding-top: 1.0rem;
	padding-bottom: 1.0rem;
	form {
		margin-bottom: 1rem;
	}
	.form-control {
		padding: .4rem .75rem;
	}
	.btn {
		background: $gold;
		color: $white;
		border-color: $black;
	}
}


.card {
	border-radius: 0px;
	border: 1px solid $green;
	.card-header {
		a {
			color: inherit;
			display: block;
		}
		[data-toggle=collapse] {
			&:before {
				/*content: '\2303';
				font-family: sans-serif;
				text-align: center; */
				content: '';
				background-image: url(/img/icons/SVG/chevron-up.svg);
				background-size: 70% 70%;
				background-repeat: no-repeat;
				background-position: 50% 20%;
				float: right;
				border: 3px solid $white;
				border-radius: 50%;
				width: 50px;
				height: 50px;
				padding: 4px;
				font-size: 1.5rem;
				margin-left: 1rem;
				padding: 0.1em;
				width: 1.3em;
				height: 1.3em;
				transition: all 0.3s ease;
			}
			&.collapsed {
				&:before {
					transform: rotate(180deg);
				}
			}
		}
	}
	.card-block {
	}
}

.navbar {
	.navbar-toggler {
		border: 0;
		padding: 0;
		.navbar-toggler-icon {
			padding: 25px;
			background-image: url('/img/Buttons/Close.svg');
		}
		&.collapsed .navbar-toggler-icon {
			background-image: url('/img/Buttons/Hamburger.svg');
		}
	}
	.navbar-brand img {
		height: 50px;
		width: auto;
	}
	.navbar-collapse {
		margin-left: -1rem;
		margin-right: -1rem;
		padding-left: 1rem;
		padding-right: 1rem;
	}
	.navbar-nav {
		padding-bottom: 1rem;
		.nav-link {
			@include media-breakpoint-down(md) {
				font-size: 1.5rem;
			}
			color: $green;
		}
	}
}

.form-control {
	border-color: $black;
}

/* custom classes ***************************************************** */

.large-logo {
	img {
		width:300px;
		height:auto;
	}
}

.fixed-top+.bg-hero.bg-hero-half { // add space below the fixed navbar
	padding-top: $nav-height;
}

.weight-semibold {
	font-weight: 500 !important;
}
.weight-normal {
	font-weight: 400 !important;
}
.bg-size-cover {
	background-size: cover;
	background-position: center center;
}
.bg-size-contain {
	background-size: contain;
}
.btn-default {
	background-color: $green;
	border-color: $white;
	color: $white;
}

.navbar.bg-faded {
	background-color: rgba(255,255,255,0.92);
	transition: background-color 0.5s ease;
	.navbar-brand {
		transition: opacity 0.5s ease;
		opacity: 1;
	}
	&.above-hero {
		//background-color: #c9ecff; // sky blue
		//background: linear-gradient(to right,#c9ecff 0%,#e8fdfe 100%);
		@include media-breakpoint-up(lg) { // needs to always have a background for mobile dropdown nav
			background-color: rgba(247,247,247,0);
		}
		@include media-breakpoint-down(md) {
			background-color: rgba(247,247,247,0);
			div#header-nav {
				background-color: rgba(255,255,255,0.92);
			}
		}
		@include media-breakpoint-down(md) {
			.navbar-brand {
				opacity: 0;
			}
		}
		&.above-hero-home {
			.navbar-brand {
				opacity: 0;
			}
		}
		.collapse.show {
		//	background-color: rgba(255,255,255,0.92);
		}
	}
}
.bg-hero {
    background-position: center top;
    //background-size: auto 1000px;
    background-size: cover;
    min-height: 950px;
    height: 100vh; // calc(100vh - #{$nav-height});
	padding-top: 100px;
	padding-bottom: 200px;
	//@media screen and (min-width: 1400px) {	}
	&.bg-hero-half {
		background-size: cover;
		background-position: center bottom;
		min-height: 17vw;
		height: auto;
		padding-top: 50px;
		padding-bottom: 100px;
		background-position: center 80%;
	}
}

.card-footer {
	background-color: transparent;
	border-width: 0;
	.btn {
		box-shadow: 1px 1px 4px rgba(0,0,0,0.4);
		margin-bottom: 50px;
		width: 200px;
		min-width: 0;
		max-width: 100%;
	}
}

.bg-gold, .bg-green, .bg-brown {
	color: $white;
	.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 { 
		color: inherit;
	}
	.card, .card-header, .card-block, .card-footer {
		background-color: transparent;
	}
	.card {
		border-width: 0;
		//border-radius: 0; // redundant
		border-top: 2px solid rgba(255,255,255,0.5);
		&:first-child {
			border-top: 0;
		}
	}
}
.btn.close {
	background-image: url('/img/Buttons/Close.svg');
	font-size: 0;
	min-width: 0;
	border-radius: 50%;
	width: 44px; // 2rem;
	height: 44px; // 2rem;
	opacity: 1.0;
	position: absolute;
	right: 1.25rem;
	top: 1.25rem;
	& + * {
		margin-top: 1rem;
	}
}

.bg-gold {
	background-color: $gold !important;
}
.bg-green {
	background-color: $green !important;
	a {
		color: $white;
		text-decoration: underline;
	}
}
.bg-brown {
	background-color: $brown !important;
}
.color-gold {
	color: $gold;
}
.color-green {
	color: $green;
}
.color-brown {
	color: $brown;
}
.border-white {
	border: 1px solid $white;
}
.border-black {
	border: 1px solid $black;
}
.border-green {
	border: 1px solid $green;
}
.border-brown {
	border: 1px solid $brown;
}

.columns-two {
	@include media-breakpoint-up(lg) {
		column-count: 2;
		column-gap: $list-group-item-padding-x;
	}
	padding-left: 0;
	> li, > dt, > dd {
		margin-left: $list-group-item-padding-x;
		break-inside: avoid;
	}
	&.list-unstyled {
		> li, > dt, > dd {
			//text-indent: -#{$list-group-item-padding-x};
			padding-bottom: 0.6rem;
		}
	}
}

.callout {
	font-size: $font-size-callout;
	font-weight: $font-weight-callout;
	@include media-breakpoint-up(lg) { // desktop styles
		font-size: $font-size-callout-desktop;
	}
	#accordion li:not(:last-of-type) {
		margin-bottom: 1rem;
	}
}
.callout-title {
	font-size: $font-size-callout-title;
	font-weight: $font-weight-callout-title;
	@include media-breakpoint-up(lg) { // desktop styles
		font-size: $font-size-callout-title-desktop;
	}
}

/* flex-borders ************************************************ */

.flex-borders {
	border: 1px solid $black;
	border-right-width: 0;
	border-bottom-width: 0;
	> * {
		border-style: inherit;
		border-color: inherit;
		border-width: 1px; // won't inherit since some borders are zero
		border-top-width: 0;
		border-left-width: 0;
	}
	&.border-white {
		border-color: $white;
	}
	&.border-green {
		border-color: $green;
	}
	&.border-brown {
		border-color: $brown;
	}
	&.border-gold {
		border-color: $gold;
	}
}

/* -square and -notsquare classes *************************************** */

.col-12.square {
	min-height: 100vw;
}
.col-6.square {
	min-height: 50vw;
}
.col-3.square {
	min-height: 25vw;
}
$previous-breakpoints: ();
@each $key,$value in $grid-breakpoints {
	$previous-breakpoints: append($previous-breakpoints,$key);
	@include media-breakpoint-up($key) {
		.col-12.#{$key}-square {
			min-height: 100vw;
		}
		.col-6.#{$key}-square {
			min-height: 50vw;
		}
		.col-3.#{$key}-square {
			min-height: 25vw;
		}
		.col-12.#{$key}-notsquare,
		.col-6.#{$key}-notsquare,
		.col-3.#{$key}-notsquare {
			min-height: 0;
		}
		@each $item in $previous-breakpoints {
			.col-#{$key}-12.#{$item}-square {
				min-height: 100vw;
			}
			.col-#{$key}-6.#{$item}-square {
				min-height: 50vw;
			}
			.col-#{$key}-3.#{$item}-square {
				min-height: 25vw;
			}
			@each $item2 in $previous-breakpoints {
				.col-#{$key}-6 .col-#{$item2}-6.#{$item}-square, {
				  min-height: 25vw;
				}
				.col-#{$item}-12.#{$item2}-notsquare {
					min-height: 25vw;
				}
			}
		}
	}
}