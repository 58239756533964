/* Contact */

iframe.google-map {
	max-width: 100%;
	@include media-breakpoint-down(md) {
		max-height: 300px;
	}
}
.required {
	&:after {
		color: red;
		content: " *";
	}
}
button[disabled] {
	font-style: italic;
	&, &:hover {
		color: $white;
	}
}
.form-control:focus {
	border-color: $green;
}