@charset "UTF-8";
/* variables ***************************************************** */
/* bootstrap variable overrides ***************************************************** */
html {
  font-size: 18px; }

/* non-bootstrap custom variables ******************************* */
/* imports ******************************************************* */
/*!
 * Bootstrap v4.0.0-alpha.6 (https://getbootstrap.com)
 * Copyright 2011-2017 The Bootstrap Authors
 * Copyright 2011-2017 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
@media print {
  *,
  *::before,
  *::after,
  p::first-letter,
  div::first-letter,
  blockquote::first-letter,
  li::first-letter,
  p::first-line,
  div::first-line,
  blockquote::first-line,
  li::first-line {
    text-shadow: none !important;
    box-shadow: none !important; }
  a,
  a:visited {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #ddd !important; } }

html {
  box-sizing: border-box;
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent; }

*,
*::before,
*::after {
  box-sizing: inherit; }

@-ms-viewport {
  width: device-width; }

body {
  margin: 0;
  font-family: "futura-pt", "Century Gothic", Verdana, sans-serif;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.4;
  color: #152c1f;
  background-color: #ffffff; }

[tabindex="-1"]:focus {
  outline: none !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: .5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: bold; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

dfn {
  font-style: italic; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #008c44;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }
  a:hover {
    color: #00401f;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):focus, a:not([href]):not([tabindex]):hover {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg:not(:root) {
  overflow: hidden; }

a,
area,
button,
[role="button"],
input,
label,
select,
summary,
textarea {
  touch-action: manipulation; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #636c72;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: left; }

label {
  display: inline-block;
  margin-bottom: .5rem; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }
  input[type="radio"]:disabled,
  input[type="checkbox"]:disabled {
    cursor: not-allowed; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 900;
  line-height: 1.1;
  color: #008c44; }

h1, .h1 {
  font-size: 46px; }

h2, .h2 {
  font-size: 40px; }

h3, .h3 {
  font-size: 32px; }

h4, .h4 {
  font-size: 24px; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.1; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.1; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.1; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.1; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(21, 44, 31, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: normal; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 5px; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  font-size: 1.25rem;
  border-left: 0.25rem solid #eceeef; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #636c72; }
  .blockquote-footer::before {
    content: "\2014 \00A0"; }

.blockquote-reverse {
  padding-right: 1rem;
  padding-left: 0;
  text-align: right;
  border-right: 0.25rem solid #eceeef;
  border-left: 0; }

.blockquote-reverse .blockquote-footer::before {
  content: ""; }

.blockquote-reverse .blockquote-footer::after {
  content: "\00A0 \2014"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 0.25rem;
  transition: all 0.2s ease-in-out;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #636c72; }

code,
kbd,
pre,
samp {
  font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

code {
  padding: 0.2rem 0.4rem;
  font-size: 90%;
  color: #bd4147;
  background-color: #f7f7f9;
  border-radius: 0.25rem; }
  a > code {
    padding: 0;
    color: inherit;
    background-color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 90%;
  color: #ffffff;
  background-color: #292b2c;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: bold; }

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 90%;
  color: #292b2c; }
  pre code {
    padding: 0;
    font-size: inherit;
    color: inherit;
    background-color: transparent;
    border-radius: 0; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  position: relative;
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px; }
  @media (min-width: 576px) {
    .container {
      padding-right: 15px;
      padding-left: 15px; } }
  @media (min-width: 800px) {
    .container {
      padding-right: 15px;
      padding-left: 15px; } }
  @media (min-width: 992px) {
    .container {
      padding-right: 15px;
      padding-left: 15px; } }
  @media (min-width: 1200px) {
    .container {
      padding-right: 15px;
      padding-left: 15px; } }
  @media (min-width: 576px) {
    .container {
      width: 540px;
      max-width: 100%; } }
  @media (min-width: 800px) {
    .container {
      width: 720px;
      max-width: 100%; } }
  @media (min-width: 992px) {
    .container {
      width: 960px;
      max-width: 100%; } }
  @media (min-width: 1200px) {
    .container {
      width: 1140px;
      max-width: 100%; } }

.container-fluid {
  position: relative;
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px; }
  @media (min-width: 576px) {
    .container-fluid {
      padding-right: 15px;
      padding-left: 15px; } }
  @media (min-width: 800px) {
    .container-fluid {
      padding-right: 15px;
      padding-left: 15px; } }
  @media (min-width: 992px) {
    .container-fluid {
      padding-right: 15px;
      padding-left: 15px; } }
  @media (min-width: 1200px) {
    .container-fluid {
      padding-right: 15px;
      padding-left: 15px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }
  @media (min-width: 576px) {
    .row {
      margin-right: -15px;
      margin-left: -15px; } }
  @media (min-width: 800px) {
    .row {
      margin-right: -15px;
      margin-left: -15px; } }
  @media (min-width: 992px) {
    .row {
      margin-right: -15px;
      margin-left: -15px; } }
  @media (min-width: 1200px) {
    .row {
      margin-right: -15px;
      margin-left: -15px; } }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }
  @media (min-width: 576px) {
    .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl {
      padding-right: 15px;
      padding-left: 15px; } }
  @media (min-width: 800px) {
    .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl {
      padding-right: 15px;
      padding-left: 15px; } }
  @media (min-width: 992px) {
    .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl {
      padding-right: 15px;
      padding-left: 15px; } }
  @media (min-width: 1200px) {
    .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl {
      padding-right: 15px;
      padding-left: 15px; } }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.pull-0 {
  right: auto; }

.pull-1 {
  right: 8.33333%; }

.pull-2 {
  right: 16.66667%; }

.pull-3 {
  right: 25%; }

.pull-4 {
  right: 33.33333%; }

.pull-5 {
  right: 41.66667%; }

.pull-6 {
  right: 50%; }

.pull-7 {
  right: 58.33333%; }

.pull-8 {
  right: 66.66667%; }

.pull-9 {
  right: 75%; }

.pull-10 {
  right: 83.33333%; }

.pull-11 {
  right: 91.66667%; }

.pull-12 {
  right: 100%; }

.push-0 {
  left: auto; }

.push-1 {
  left: 8.33333%; }

.push-2 {
  left: 16.66667%; }

.push-3 {
  left: 25%; }

.push-4 {
  left: 33.33333%; }

.push-5 {
  left: 41.66667%; }

.push-6 {
  left: 50%; }

.push-7 {
  left: 58.33333%; }

.push-8 {
  left: 66.66667%; }

.push-9 {
  left: 75%; }

.push-10 {
  left: 83.33333%; }

.push-11 {
  left: 91.66667%; }

.push-12 {
  left: 100%; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .pull-sm-0 {
    right: auto; }
  .pull-sm-1 {
    right: 8.33333%; }
  .pull-sm-2 {
    right: 16.66667%; }
  .pull-sm-3 {
    right: 25%; }
  .pull-sm-4 {
    right: 33.33333%; }
  .pull-sm-5 {
    right: 41.66667%; }
  .pull-sm-6 {
    right: 50%; }
  .pull-sm-7 {
    right: 58.33333%; }
  .pull-sm-8 {
    right: 66.66667%; }
  .pull-sm-9 {
    right: 75%; }
  .pull-sm-10 {
    right: 83.33333%; }
  .pull-sm-11 {
    right: 91.66667%; }
  .pull-sm-12 {
    right: 100%; }
  .push-sm-0 {
    left: auto; }
  .push-sm-1 {
    left: 8.33333%; }
  .push-sm-2 {
    left: 16.66667%; }
  .push-sm-3 {
    left: 25%; }
  .push-sm-4 {
    left: 33.33333%; }
  .push-sm-5 {
    left: 41.66667%; }
  .push-sm-6 {
    left: 50%; }
  .push-sm-7 {
    left: 58.33333%; }
  .push-sm-8 {
    left: 66.66667%; }
  .push-sm-9 {
    left: 75%; }
  .push-sm-10 {
    left: 83.33333%; }
  .push-sm-11 {
    left: 91.66667%; }
  .push-sm-12 {
    left: 100%; }
  .offset-sm-0 {
    margin-left: 0%; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 800px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .pull-md-0 {
    right: auto; }
  .pull-md-1 {
    right: 8.33333%; }
  .pull-md-2 {
    right: 16.66667%; }
  .pull-md-3 {
    right: 25%; }
  .pull-md-4 {
    right: 33.33333%; }
  .pull-md-5 {
    right: 41.66667%; }
  .pull-md-6 {
    right: 50%; }
  .pull-md-7 {
    right: 58.33333%; }
  .pull-md-8 {
    right: 66.66667%; }
  .pull-md-9 {
    right: 75%; }
  .pull-md-10 {
    right: 83.33333%; }
  .pull-md-11 {
    right: 91.66667%; }
  .pull-md-12 {
    right: 100%; }
  .push-md-0 {
    left: auto; }
  .push-md-1 {
    left: 8.33333%; }
  .push-md-2 {
    left: 16.66667%; }
  .push-md-3 {
    left: 25%; }
  .push-md-4 {
    left: 33.33333%; }
  .push-md-5 {
    left: 41.66667%; }
  .push-md-6 {
    left: 50%; }
  .push-md-7 {
    left: 58.33333%; }
  .push-md-8 {
    left: 66.66667%; }
  .push-md-9 {
    left: 75%; }
  .push-md-10 {
    left: 83.33333%; }
  .push-md-11 {
    left: 91.66667%; }
  .push-md-12 {
    left: 100%; }
  .offset-md-0 {
    margin-left: 0%; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .pull-lg-0 {
    right: auto; }
  .pull-lg-1 {
    right: 8.33333%; }
  .pull-lg-2 {
    right: 16.66667%; }
  .pull-lg-3 {
    right: 25%; }
  .pull-lg-4 {
    right: 33.33333%; }
  .pull-lg-5 {
    right: 41.66667%; }
  .pull-lg-6 {
    right: 50%; }
  .pull-lg-7 {
    right: 58.33333%; }
  .pull-lg-8 {
    right: 66.66667%; }
  .pull-lg-9 {
    right: 75%; }
  .pull-lg-10 {
    right: 83.33333%; }
  .pull-lg-11 {
    right: 91.66667%; }
  .pull-lg-12 {
    right: 100%; }
  .push-lg-0 {
    left: auto; }
  .push-lg-1 {
    left: 8.33333%; }
  .push-lg-2 {
    left: 16.66667%; }
  .push-lg-3 {
    left: 25%; }
  .push-lg-4 {
    left: 33.33333%; }
  .push-lg-5 {
    left: 41.66667%; }
  .push-lg-6 {
    left: 50%; }
  .push-lg-7 {
    left: 58.33333%; }
  .push-lg-8 {
    left: 66.66667%; }
  .push-lg-9 {
    left: 75%; }
  .push-lg-10 {
    left: 83.33333%; }
  .push-lg-11 {
    left: 91.66667%; }
  .push-lg-12 {
    left: 100%; }
  .offset-lg-0 {
    margin-left: 0%; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .pull-xl-0 {
    right: auto; }
  .pull-xl-1 {
    right: 8.33333%; }
  .pull-xl-2 {
    right: 16.66667%; }
  .pull-xl-3 {
    right: 25%; }
  .pull-xl-4 {
    right: 33.33333%; }
  .pull-xl-5 {
    right: 41.66667%; }
  .pull-xl-6 {
    right: 50%; }
  .pull-xl-7 {
    right: 58.33333%; }
  .pull-xl-8 {
    right: 66.66667%; }
  .pull-xl-9 {
    right: 75%; }
  .pull-xl-10 {
    right: 83.33333%; }
  .pull-xl-11 {
    right: 91.66667%; }
  .pull-xl-12 {
    right: 100%; }
  .push-xl-0 {
    left: auto; }
  .push-xl-1 {
    left: 8.33333%; }
  .push-xl-2 {
    left: 16.66667%; }
  .push-xl-3 {
    left: 25%; }
  .push-xl-4 {
    left: 33.33333%; }
  .push-xl-5 {
    left: 41.66667%; }
  .push-xl-6 {
    left: 50%; }
  .push-xl-7 {
    left: 58.33333%; }
  .push-xl-8 {
    left: 66.66667%; }
  .push-xl-9 {
    left: 75%; }
  .push-xl-10 {
    left: 83.33333%; }
  .push-xl-11 {
    left: 91.66667%; }
  .push-xl-12 {
    left: 100%; }
  .offset-xl-0 {
    margin-left: 0%; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #eceeef; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #eceeef; }
  .table tbody + tbody {
    border-top: 2px solid #eceeef; }
  .table .table {
    background-color: #ffffff; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #eceeef; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #eceeef; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(21, 44, 31, 0.05); }

.table-hover tbody tr:hover {
  background-color: rgba(21, 44, 31, 0.075); }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(21, 44, 31, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(13, 27, 19, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(13, 27, 19, 0.075); }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #dff0d8; }

.table-hover .table-success:hover {
  background-color: #d0e9c6; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #d0e9c6; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #d9edf7; }

.table-hover .table-info:hover {
  background-color: #c4e3f3; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #c4e3f3; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fcf8e3; }

.table-hover .table-warning:hover {
  background-color: #faf2cc; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #faf2cc; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f2dede; }

.table-hover .table-danger:hover {
  background-color: #ebcccc; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #ebcccc; }

.thead-inverse th {
  color: #ffffff;
  background-color: #292b2c; }

.thead-default th {
  color: #464a4c;
  background-color: #eceeef; }

.table-inverse {
  color: #ffffff;
  background-color: #292b2c; }
  .table-inverse th,
  .table-inverse td,
  .table-inverse thead th {
    border-color: #3b3e40; }
  .table-inverse.table-bordered {
    border: 0; }
  .table-inverse.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-inverse.table-hover tbody tr:hover {
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 991px) {
  .table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive.table-bordered {
      border: 0; } }

.form-control {
  display: block;
  width: 100%;
  padding: 0.5rem 0.75rem;
  font-size: 1rem;
  line-height: 1.25;
  color: #464a4c;
  background-color: #ffffff;
  background-image: none;
  background-clip: padding-box;
  border: 1px solid rgba(21, 44, 31, 0.15);
  border-radius: 0.25rem;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s; }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #464a4c;
    background-color: #ffffff;
    border-color: #0dff82;
    outline: none; }
  .form-control::placeholder {
    color: #636c72;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #eceeef;
    opacity: 1; }
  .form-control:disabled {
    cursor: not-allowed; }

select.form-control:not([size]):not([multiple]) {
  height: calc(2.25rem + 2px); }

select.form-control:focus::-ms-value {
  color: #464a4c;
  background-color: #ffffff; }

.form-control-file,
.form-control-range {
  display: block; }

.col-form-label {
  padding-top: calc(0.5rem - 1px * 2);
  padding-bottom: calc(0.5rem - 1px * 2);
  margin-bottom: 0; }

.col-form-label-lg {
  padding-top: calc(0.75rem - 1px * 2);
  padding-bottom: calc(0.75rem - 1px * 2);
  font-size: 1.25rem; }

.col-form-label-sm {
  padding-top: calc(0.25rem - 1px * 2);
  padding-bottom: calc(0.25rem - 1px * 2);
  font-size: 0.875rem; }

.col-form-legend {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-bottom: 0;
  font-size: 1rem; }

.form-control-static {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-bottom: 0;
  line-height: 1.25;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-static.form-control-sm, .input-group-sm > .form-control-static.form-control,
  .input-group-sm > .form-control-static.input-group-addon,
  .input-group-sm > .input-group-btn > .form-control-static.btn, .form-control-static.form-control-lg, .input-group-lg > .form-control-static.form-control,
  .input-group-lg > .form-control-static.input-group-addon,
  .input-group-lg > .input-group-btn > .form-control-static.btn {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm, .input-group-sm > .form-control,
.input-group-sm > .input-group-addon,
.input-group-sm > .input-group-btn > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem; }

select.form-control-sm:not([size]):not([multiple]), .input-group-sm > select.form-control:not([size]):not([multiple]),
.input-group-sm > select.input-group-addon:not([size]):not([multiple]),
.input-group-sm > .input-group-btn > select.btn:not([size]):not([multiple]) {
  height: 1.8125rem; }

.form-control-lg, .input-group-lg > .form-control,
.input-group-lg > .input-group-addon,
.input-group-lg > .input-group-btn > .btn {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  border-radius: 0.3rem; }

select.form-control-lg:not([size]):not([multiple]), .input-group-lg > select.form-control:not([size]):not([multiple]),
.input-group-lg > select.input-group-addon:not([size]):not([multiple]),
.input-group-lg > .input-group-btn > select.btn:not([size]):not([multiple]) {
  height: 3.16667rem; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-check {
  position: relative;
  display: block;
  margin-bottom: 0.5rem; }
  .form-check.disabled .form-check-label {
    color: #636c72;
    cursor: not-allowed; }

.form-check-label {
  padding-left: 1.25rem;
  margin-bottom: 0; }

.form-check-input {
  position: absolute;
  margin-top: 0.25rem;
  margin-left: -1.25rem; }
  .form-check-input:only-child {
    position: static; }

.form-check-inline {
  display: inline-block; }
  .form-check-inline .form-check-label {
    vertical-align: middle; }
  .form-check-inline + .form-check-inline {
    margin-left: 0.75rem; }

.form-control-feedback {
  margin-top: 0.25rem; }

.form-control-success,
.form-control-warning,
.form-control-danger {
  padding-right: 2.25rem;
  background-repeat: no-repeat;
  background-position: center right 0.5625rem;
  background-size: 1.125rem 1.125rem; }

.has-success .form-control-feedback,
.has-success .form-control-label,
.has-success .col-form-label,
.has-success .form-check-label,
.has-success .custom-control {
  color: #008c44; }

.has-success .form-control,
.has-success .custom-select,
.has-success .custom-file-control {
  border-color: #008c44; }

.has-success .input-group-addon {
  color: #008c44;
  background-color: #59ffaa;
  border-color: #008c44; }

.has-success .form-control-success {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23008c44' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3E%3C/svg%3E"); }

.has-warning .form-control-feedback,
.has-warning .form-control-label,
.has-warning .col-form-label,
.has-warning .form-check-label,
.has-warning .custom-control {
  color: #f0ad4e; }

.has-warning .form-control,
.has-warning .custom-select,
.has-warning .custom-file-control {
  border-color: #f0ad4e; }

.has-warning .input-group-addon {
  color: #f0ad4e;
  background-color: white;
  border-color: #f0ad4e; }

.has-warning .form-control-warning {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23f0ad4e' d='M4.4 5.324h-.8v-2.46h.8zm0 1.42h-.8V5.89h.8zM3.76.63L.04 7.075c-.115.2.016.425.26.426h7.397c.242 0 .372-.226.258-.426C6.726 4.924 5.47 2.79 4.253.63c-.113-.174-.39-.174-.494 0z'/%3E%3C/svg%3E"); }

.has-danger .form-control-feedback,
.has-danger .form-control-label,
.has-danger .col-form-label,
.has-danger .form-check-label,
.has-danger .custom-control {
  color: #d9534f; }

.has-danger .form-control,
.has-danger .custom-select,
.has-danger .custom-file-control {
  border-color: #d9534f; }

.has-danger .input-group-addon {
  color: #d9534f;
  background-color: #fdf7f7;
  border-color: #d9534f; }

.has-danger .form-control-danger {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23d9534f' viewBox='-2 -2 7 7'%3E%3Cpath stroke='%23d9534f' d='M0 0l3 3m0-3L0 3'/%3E%3Ccircle r='.5'/%3E%3Ccircle cx='3' r='.5'/%3E%3Ccircle cy='3' r='.5'/%3E%3Ccircle cx='3' cy='3' r='.5'/%3E%3C/svg%3E"); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-static {
      display: inline-block; }
    .form-inline .input-group {
      width: auto; }
    .form-inline .form-control-label {
      margin-bottom: 0;
      vertical-align: middle; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      margin-top: 0;
      margin-bottom: 0; }
    .form-inline .form-check-label {
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 0; }
    .form-inline .custom-control-indicator {
      position: static;
      display: inline-block;
      margin-right: 0.25rem;
      vertical-align: text-bottom; }
    .form-inline .has-feedback .form-control-feedback {
      top: 0; } }

.btn {
  display: inline-block;
  font-weight: 500;
  line-height: 1.25;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: all 0.2s ease-in-out; }
  .btn:focus, .btn:hover {
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 2px rgba(0, 140, 68, 0.25); }
  .btn.disabled, .btn:disabled {
    cursor: not-allowed;
    opacity: .65; }
  .btn:active, .btn.active {
    background-image: none; }

a.btn.disabled,
fieldset[disabled] a.btn {
  pointer-events: none; }

.btn-primary {
  color: #ffffff;
  background-color: #008c44;
  border-color: #008c44; }
  .btn-primary:hover {
    color: #ffffff;
    background-color: #00592b;
    border-color: #004f26; }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 2px rgba(0, 140, 68, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    background-color: #008c44;
    border-color: #008c44; }
  .btn-primary:active, .btn-primary.active,
  .show > .btn-primary.dropdown-toggle {
    color: #ffffff;
    background-color: #00592b;
    background-image: none;
    border-color: #004f26; }

.btn-secondary {
  color: #292b2c;
  background-color: #ffffff;
  border-color: #ccc; }
  .btn-secondary:hover {
    color: #292b2c;
    background-color: #e6e6e6;
    border-color: #adadad; }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 0 0 2px rgba(204, 204, 204, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    background-color: #ffffff;
    border-color: #ccc; }
  .btn-secondary:active, .btn-secondary.active,
  .show > .btn-secondary.dropdown-toggle {
    color: #292b2c;
    background-color: #e6e6e6;
    background-image: none;
    border-color: #adadad; }

.btn-info {
  color: #ffffff;
  background-color: #5bc0de;
  border-color: #5bc0de; }
  .btn-info:hover {
    color: #ffffff;
    background-color: #31b0d5;
    border-color: #2aabd2; }
  .btn-info:focus, .btn-info.focus {
    box-shadow: 0 0 0 2px rgba(91, 192, 222, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    background-color: #5bc0de;
    border-color: #5bc0de; }
  .btn-info:active, .btn-info.active,
  .show > .btn-info.dropdown-toggle {
    color: #ffffff;
    background-color: #31b0d5;
    background-image: none;
    border-color: #2aabd2; }

.btn-success {
  color: #ffffff;
  background-color: #008c44;
  border-color: #008c44; }
  .btn-success:hover {
    color: #ffffff;
    background-color: #00592b;
    border-color: #004f26; }
  .btn-success:focus, .btn-success.focus {
    box-shadow: 0 0 0 2px rgba(0, 140, 68, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    background-color: #008c44;
    border-color: #008c44; }
  .btn-success:active, .btn-success.active,
  .show > .btn-success.dropdown-toggle {
    color: #ffffff;
    background-color: #00592b;
    background-image: none;
    border-color: #004f26; }

.btn-warning {
  color: #ffffff;
  background-color: #f0ad4e;
  border-color: #f0ad4e; }
  .btn-warning:hover {
    color: #ffffff;
    background-color: #ec971f;
    border-color: #eb9316; }
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: 0 0 0 2px rgba(240, 173, 78, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    background-color: #f0ad4e;
    border-color: #f0ad4e; }
  .btn-warning:active, .btn-warning.active,
  .show > .btn-warning.dropdown-toggle {
    color: #ffffff;
    background-color: #ec971f;
    background-image: none;
    border-color: #eb9316; }

.btn-danger {
  color: #ffffff;
  background-color: #d9534f;
  border-color: #d9534f; }
  .btn-danger:hover {
    color: #ffffff;
    background-color: #c9302c;
    border-color: #c12e2a; }
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 0 0 2px rgba(217, 83, 79, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    background-color: #d9534f;
    border-color: #d9534f; }
  .btn-danger:active, .btn-danger.active,
  .show > .btn-danger.dropdown-toggle {
    color: #ffffff;
    background-color: #c9302c;
    background-image: none;
    border-color: #c12e2a; }

.btn-outline-primary {
  color: #008c44;
  background-color: transparent;
  background-image: none;
  border-color: #008c44; }
  .btn-outline-primary:hover {
    color: #ffffff;
    background-color: #008c44;
    border-color: #008c44; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 2px rgba(0, 140, 68, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #008c44;
    background-color: transparent; }
  .btn-outline-primary:active, .btn-outline-primary.active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #ffffff;
    background-color: #008c44;
    border-color: #008c44; }

.btn-outline-secondary {
  color: #ccc;
  background-color: transparent;
  background-image: none;
  border-color: #ccc; }
  .btn-outline-secondary:hover {
    color: #292b2c;
    background-color: #ccc;
    border-color: #ccc; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 2px rgba(204, 204, 204, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #ccc;
    background-color: transparent; }
  .btn-outline-secondary:active, .btn-outline-secondary.active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #292b2c;
    background-color: #ccc;
    border-color: #ccc; }

.btn-outline-info {
  color: #5bc0de;
  background-color: transparent;
  background-image: none;
  border-color: #5bc0de; }
  .btn-outline-info:hover {
    color: #ffffff;
    background-color: #5bc0de;
    border-color: #5bc0de; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 2px rgba(91, 192, 222, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #5bc0de;
    background-color: transparent; }
  .btn-outline-info:active, .btn-outline-info.active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #ffffff;
    background-color: #5bc0de;
    border-color: #5bc0de; }

.btn-outline-success {
  color: #008c44;
  background-color: transparent;
  background-image: none;
  border-color: #008c44; }
  .btn-outline-success:hover {
    color: #ffffff;
    background-color: #008c44;
    border-color: #008c44; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 2px rgba(0, 140, 68, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #008c44;
    background-color: transparent; }
  .btn-outline-success:active, .btn-outline-success.active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #ffffff;
    background-color: #008c44;
    border-color: #008c44; }

.btn-outline-warning {
  color: #f0ad4e;
  background-color: transparent;
  background-image: none;
  border-color: #f0ad4e; }
  .btn-outline-warning:hover {
    color: #ffffff;
    background-color: #f0ad4e;
    border-color: #f0ad4e; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 2px rgba(240, 173, 78, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #f0ad4e;
    background-color: transparent; }
  .btn-outline-warning:active, .btn-outline-warning.active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #ffffff;
    background-color: #f0ad4e;
    border-color: #f0ad4e; }

.btn-outline-danger {
  color: #d9534f;
  background-color: transparent;
  background-image: none;
  border-color: #d9534f; }
  .btn-outline-danger:hover {
    color: #ffffff;
    background-color: #d9534f;
    border-color: #d9534f; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 2px rgba(217, 83, 79, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #d9534f;
    background-color: transparent; }
  .btn-outline-danger:active, .btn-outline-danger.active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #ffffff;
    background-color: #d9534f;
    border-color: #d9534f; }

.btn-link {
  font-weight: normal;
  color: #008c44;
  border-radius: 0; }
  .btn-link, .btn-link:active, .btn-link.active, .btn-link:disabled {
    background-color: transparent; }
  .btn-link, .btn-link:focus, .btn-link:active {
    border-color: transparent; }
  .btn-link:hover {
    border-color: transparent; }
  .btn-link:focus, .btn-link:hover {
    color: #00401f;
    text-decoration: underline;
    background-color: transparent; }
  .btn-link:disabled {
    color: #636c72; }
    .btn-link:disabled:focus, .btn-link:disabled:hover {
      text-decoration: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }

.btn-block + .btn-block {
  margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  opacity: 0;
  transition: opacity 0.15s linear; }
  .fade.show {
    opacity: 1; }

.collapse {
  display: none; }
  .collapse.show {
    display: block; }

tr.collapse.show {
  display: table-row; }

tbody.collapse.show {
  display: table-row-group; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }

.dropup,
.dropdown {
  position: relative; }

.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.3em;
  vertical-align: middle;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle::after {
  border-top: 0;
  border-bottom: 0.3em solid; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #152c1f;
  text-align: left;
  list-style: none;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(21, 44, 31, 0.15);
  border-radius: 0.25rem; }

.dropdown-divider {
  height: 1px;
  margin: 0.5rem 0;
  overflow: hidden;
  background-color: #eceeef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 3px 1.5rem;
  clear: both;
  font-weight: normal;
  color: #292b2c;
  text-align: inherit;
  white-space: nowrap;
  background: none;
  border: 0; }
  .dropdown-item:focus, .dropdown-item:hover {
    color: #1d1e1f;
    text-decoration: none;
    background-color: #f7f7f9; }
  .dropdown-item.active, .dropdown-item:active {
    color: #ffffff;
    text-decoration: none;
    background-color: #008c44; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #636c72;
    cursor: not-allowed;
    background-color: transparent; }

.show > .dropdown-menu {
  display: block; }

.show > a {
  outline: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #636c72;
  white-space: nowrap; }

.dropdown-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 990; }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-bottom: 0.125rem; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 0 1 auto;
    margin-bottom: 0; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 2; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 2; }
  .btn-group .btn + .btn,
  .btn-group .btn + .btn-group,
  .btn-group .btn-group + .btn,
  .btn-group .btn-group + .btn-group,
  .btn-group-vertical .btn + .btn,
  .btn-group-vertical .btn + .btn-group,
  .btn-group-vertical .btn-group + .btn,
  .btn-group-vertical .btn-group + .btn-group {
    margin-left: -1px; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child):not(:last-child):not(.dropdown-toggle) {
  border-radius: 0; }

.btn-group > .btn:first-child {
  margin-left: 0; }
  .btn-group > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }

.btn-group > .btn:last-child:not(:first-child),
.btn-group > .dropdown-toggle:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.btn-group > .btn-group {
  float: left; }

.btn-group > .btn-group:not(:first-child):not(:last-child) > .btn {
  border-radius: 0; }

.btn-group > .btn-group:first-child:not(:last-child) > .btn:last-child,
.btn-group > .btn-group:first-child:not(:last-child) > .dropdown-toggle {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn-group:last-child:not(:first-child) > .btn:first-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.btn-group .dropdown-toggle:active,
.btn-group.open .dropdown-toggle {
  outline: 0; }

.btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }
  .btn + .dropdown-toggle-split::after {
    margin-left: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 1.125rem;
  padding-left: 1.125rem; }

.btn-group-vertical {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical .btn,
  .btn-group-vertical .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn + .btn,
  .btn-group-vertical > .btn + .btn-group,
  .btn-group-vertical > .btn-group + .btn,
  .btn-group-vertical > .btn-group + .btn-group {
    margin-top: -1px;
    margin-left: 0; }

.btn-group-vertical > .btn:not(:first-child):not(:last-child) {
  border-radius: 0; }

.btn-group-vertical > .btn:first-child:not(:last-child) {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }

.btn-group-vertical > .btn:last-child:not(:first-child) {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.btn-group-vertical > .btn-group:not(:first-child):not(:last-child) > .btn {
  border-radius: 0; }

.btn-group-vertical > .btn-group:first-child:not(:last-child) > .btn:last-child,
.btn-group-vertical > .btn-group:first-child:not(:last-child) > .dropdown-toggle {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }

.btn-group-vertical > .btn-group:last-child:not(:first-child) > .btn:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

[data-toggle="buttons"] > .btn input[type="radio"],
[data-toggle="buttons"] > .btn input[type="checkbox"],
[data-toggle="buttons"] > .btn-group > .btn input[type="radio"],
[data-toggle="buttons"] > .btn-group > .btn input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  width: 100%; }
  .input-group .form-control {
    position: relative;
    z-index: 2;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0; }
    .input-group .form-control:focus, .input-group .form-control:active, .input-group .form-control:hover {
      z-index: 3; }

.input-group-addon,
.input-group-btn,
.input-group .form-control {
  display: flex;
  align-items: center; }
  .input-group-addon:not(:first-child):not(:last-child),
  .input-group-btn:not(:first-child):not(:last-child),
  .input-group .form-control:not(:first-child):not(:last-child) {
    border-radius: 0; }

.input-group-addon,
.input-group-btn {
  white-space: nowrap;
  vertical-align: middle; }

.input-group-addon {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.25;
  color: #464a4c;
  text-align: center;
  background-color: #eceeef;
  border: 1px solid rgba(21, 44, 31, 0.15);
  border-radius: 0.25rem; }
  .input-group-addon.form-control-sm,
  .input-group-sm > .input-group-addon,
  .input-group-sm > .input-group-btn > .input-group-addon.btn {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    border-radius: 0.2rem; }
  .input-group-addon.form-control-lg,
  .input-group-lg > .input-group-addon,
  .input-group-lg > .input-group-btn > .input-group-addon.btn {
    padding: 0.75rem 1.5rem;
    font-size: 1.25rem;
    border-radius: 0.3rem; }
  .input-group-addon input[type="radio"],
  .input-group-addon input[type="checkbox"] {
    margin-top: 0; }

.input-group .form-control:not(:last-child),
.input-group-addon:not(:last-child),
.input-group-btn:not(:last-child) > .btn,
.input-group-btn:not(:last-child) > .btn-group > .btn,
.input-group-btn:not(:last-child) > .dropdown-toggle,
.input-group-btn:not(:first-child) > .btn:not(:last-child):not(.dropdown-toggle),
.input-group-btn:not(:first-child) > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group-addon:not(:last-child) {
  border-right: 0; }

.input-group .form-control:not(:first-child),
.input-group-addon:not(:first-child),
.input-group-btn:not(:first-child) > .btn,
.input-group-btn:not(:first-child) > .btn-group > .btn,
.input-group-btn:not(:first-child) > .dropdown-toggle,
.input-group-btn:not(:last-child) > .btn:not(:first-child),
.input-group-btn:not(:last-child) > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.form-control + .input-group-addon:not(:first-child) {
  border-left: 0; }

.input-group-btn {
  position: relative;
  font-size: 0;
  white-space: nowrap; }
  .input-group-btn > .btn {
    position: relative; }
    .input-group-btn > .btn + .btn {
      margin-left: -1px; }
    .input-group-btn > .btn:focus, .input-group-btn > .btn:active, .input-group-btn > .btn:hover {
      z-index: 3; }
  .input-group-btn:not(:last-child) > .btn,
  .input-group-btn:not(:last-child) > .btn-group {
    margin-right: -1px; }
  .input-group-btn:not(:first-child) > .btn,
  .input-group-btn:not(:first-child) > .btn-group {
    z-index: 2;
    margin-left: -1px; }
    .input-group-btn:not(:first-child) > .btn:focus, .input-group-btn:not(:first-child) > .btn:active, .input-group-btn:not(:first-child) > .btn:hover,
    .input-group-btn:not(:first-child) > .btn-group:focus,
    .input-group-btn:not(:first-child) > .btn-group:active,
    .input-group-btn:not(:first-child) > .btn-group:hover {
      z-index: 3; }

.custom-control {
  position: relative;
  display: inline-flex;
  min-height: 1.4rem;
  padding-left: 1.5rem;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-indicator {
    color: #ffffff;
    background-color: #008c44; }
  .custom-control-input:focus ~ .custom-control-indicator {
    box-shadow: 0 0 0 1px #ffffff, 0 0 0 3px #008c44; }
  .custom-control-input:active ~ .custom-control-indicator {
    color: #ffffff;
    background-color: #40ff9d; }
  .custom-control-input:disabled ~ .custom-control-indicator {
    cursor: not-allowed;
    background-color: #eceeef; }
  .custom-control-input:disabled ~ .custom-control-description {
    color: #636c72;
    cursor: not-allowed; }

.custom-control-indicator {
  position: absolute;
  top: 0.2rem;
  left: 0;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  user-select: none;
  background-color: #ddd;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%; }

.custom-checkbox .custom-control-indicator {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-indicator {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23ffffff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-indicator {
  background-color: #008c44;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23ffffff' d='M0 2h4'/%3E%3C/svg%3E"); }

.custom-radio .custom-control-indicator {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-indicator {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23ffffff'/%3E%3C/svg%3E"); }

.custom-controls-stacked {
  display: flex;
  flex-direction: column; }
  .custom-controls-stacked .custom-control {
    margin-bottom: 0.25rem; }
    .custom-controls-stacked .custom-control + .custom-control {
      margin-left: 0; }

.custom-select {
  display: inline-block;
  max-width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  line-height: 1.25;
  color: #464a4c;
  vertical-align: middle;
  background: #ffffff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23333' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
  background-size: 8px 10px;
  border: 1px solid rgba(21, 44, 31, 0.15);
  border-radius: 0.25rem;
  appearance: none; }
  .custom-select:focus {
    border-color: #0dff82;
    outline: none; }
    .custom-select:focus::-ms-value {
      color: #464a4c;
      background-color: #ffffff; }
  .custom-select:disabled {
    color: #636c72;
    cursor: not-allowed;
    background-color: #eceeef; }
  .custom-select::-ms-expand {
    opacity: 0; }

.custom-select-sm {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 75%; }

.custom-file {
  position: relative;
  display: inline-block;
  max-width: 100%;
  height: 2.5rem;
  margin-bottom: 0; }

.custom-file-input {
  min-width: 14rem;
  max-width: 100%;
  height: 2.5rem;
  margin: 0;
  opacity: 0; }

.custom-file-control {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 5;
  height: 2.5rem;
  padding: 0.5rem 1rem;
  line-height: 1.5;
  color: #464a4c;
  pointer-events: none;
  user-select: none;
  background-color: #ffffff;
  border: 1px solid rgba(21, 44, 31, 0.15);
  border-radius: 0.25rem; }
  .custom-file-control:lang(en):empty::after {
    content: "Choose file..."; }
  .custom-file-control::before {
    position: absolute;
    top: -1px;
    right: -1px;
    bottom: -1px;
    z-index: 6;
    display: block;
    height: 2.5rem;
    padding: 0.5rem 1rem;
    line-height: 1.5;
    color: #464a4c;
    background-color: #eceeef;
    border: 1px solid rgba(21, 44, 31, 0.15);
    border-radius: 0 0.25rem 0.25rem 0; }
  .custom-file-control:lang(en)::before {
    content: "Browse"; }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5em 1em; }
  .nav-link:focus, .nav-link:hover {
    text-decoration: none; }
  .nav-link.disabled {
    color: #636c72;
    cursor: not-allowed; }

.nav-tabs {
  border-bottom: 1px solid #ddd; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
      border-color: #eceeef #eceeef #ddd; }
    .nav-tabs .nav-link.disabled {
      color: #636c72;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #464a4c;
    background-color: #ffffff;
    border-color: #ddd #ddd #ffffff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .nav-item.show .nav-link {
  color: #ffffff;
  background-color: #008c44; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0.5rem 1rem; }
  @media (max-width: 575px) {
    .navbar > .container {
      margin-right: 0;
      margin-left: 0; } }

.navbar-brand {
  display: inline-block;
  align-self: flex-start;
  padding-top: .25rem;
  padding-bottom: .25rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:focus, .navbar-brand:hover {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }

.navbar-text {
  display: inline-block;
  padding-top: .425rem;
  padding-bottom: .425rem; }

.navbar-toggler {
  align-self: flex-start;
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:focus, .navbar-toggler:hover {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

.navbar-toggler-left {
  position: absolute;
  left: 1rem; }

.navbar-toggler-right {
  position: absolute;
  right: 1rem; }

@media (max-width: 575px) {
  .navbar-toggleable .navbar-nav .dropdown-menu {
    position: static;
    float: none; }
  .navbar-toggleable > .container {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-toggleable {
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center; }
    .navbar-toggleable .navbar-nav {
      flex-direction: row; }
      .navbar-toggleable .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem; }
    .navbar-toggleable > .container {
      display: flex;
      flex-wrap: nowrap;
      align-items: center; }
    .navbar-toggleable .navbar-collapse {
      display: flex !important;
      width: 100%; }
    .navbar-toggleable .navbar-toggler {
      display: none; } }

@media (max-width: 799px) {
  .navbar-toggleable-sm .navbar-nav .dropdown-menu {
    position: static;
    float: none; }
  .navbar-toggleable-sm > .container {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 800px) {
  .navbar-toggleable-sm {
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center; }
    .navbar-toggleable-sm .navbar-nav {
      flex-direction: row; }
      .navbar-toggleable-sm .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem; }
    .navbar-toggleable-sm > .container {
      display: flex;
      flex-wrap: nowrap;
      align-items: center; }
    .navbar-toggleable-sm .navbar-collapse {
      display: flex !important;
      width: 100%; }
    .navbar-toggleable-sm .navbar-toggler {
      display: none; } }

@media (max-width: 991px) {
  .navbar-toggleable-md .navbar-nav .dropdown-menu {
    position: static;
    float: none; }
  .navbar-toggleable-md > .container {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-toggleable-md {
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center; }
    .navbar-toggleable-md .navbar-nav {
      flex-direction: row; }
      .navbar-toggleable-md .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem; }
    .navbar-toggleable-md > .container {
      display: flex;
      flex-wrap: nowrap;
      align-items: center; }
    .navbar-toggleable-md .navbar-collapse {
      display: flex !important;
      width: 100%; }
    .navbar-toggleable-md .navbar-toggler {
      display: none; } }

@media (max-width: 1199px) {
  .navbar-toggleable-lg .navbar-nav .dropdown-menu {
    position: static;
    float: none; }
  .navbar-toggleable-lg > .container {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-toggleable-lg {
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center; }
    .navbar-toggleable-lg .navbar-nav {
      flex-direction: row; }
      .navbar-toggleable-lg .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem; }
    .navbar-toggleable-lg > .container {
      display: flex;
      flex-wrap: nowrap;
      align-items: center; }
    .navbar-toggleable-lg .navbar-collapse {
      display: flex !important;
      width: 100%; }
    .navbar-toggleable-lg .navbar-toggler {
      display: none; } }

.navbar-toggleable-xl {
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center; }
  .navbar-toggleable-xl .navbar-nav .dropdown-menu {
    position: static;
    float: none; }
  .navbar-toggleable-xl > .container {
    padding-right: 0;
    padding-left: 0; }
  .navbar-toggleable-xl .navbar-nav {
    flex-direction: row; }
    .navbar-toggleable-xl .navbar-nav .nav-link {
      padding-right: .5rem;
      padding-left: .5rem; }
  .navbar-toggleable-xl > .container {
    display: flex;
    flex-wrap: nowrap;
    align-items: center; }
  .navbar-toggleable-xl .navbar-collapse {
    display: flex !important;
    width: 100%; }
  .navbar-toggleable-xl .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand,
.navbar-light .navbar-toggler {
  color: rgba(21, 44, 31, 0.9); }
  .navbar-light .navbar-brand:focus, .navbar-light .navbar-brand:hover,
  .navbar-light .navbar-toggler:focus,
  .navbar-light .navbar-toggler:hover {
    color: rgba(21, 44, 31, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(21, 44, 31, 0.5); }
  .navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
    color: rgba(21, 44, 31, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(21, 44, 31, 0.3); }

.navbar-light .navbar-nav .open > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.open,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(21, 44, 31, 0.9); }

.navbar-light .navbar-toggler {
  border-color: rgba(21, 44, 31, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(21, 44, 31, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-light .navbar-text {
  color: rgba(21, 44, 31, 0.5); }

.navbar-inverse .navbar-brand,
.navbar-inverse .navbar-toggler {
  color: white; }
  .navbar-inverse .navbar-brand:focus, .navbar-inverse .navbar-brand:hover,
  .navbar-inverse .navbar-toggler:focus,
  .navbar-inverse .navbar-toggler:hover {
    color: white; }

.navbar-inverse .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-inverse .navbar-nav .nav-link:focus, .navbar-inverse .navbar-nav .nav-link:hover {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-inverse .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-inverse .navbar-nav .open > .nav-link,
.navbar-inverse .navbar-nav .active > .nav-link,
.navbar-inverse .navbar-nav .nav-link.open,
.navbar-inverse .navbar-nav .nav-link.active {
  color: white; }

.navbar-inverse .navbar-toggler {
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-inverse .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-inverse .navbar-text {
  color: rgba(255, 255, 255, 0.5); }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border: 1px solid rgba(21, 44, 31, 0.125);
  border-radius: 0.25rem; }

.card-block {
  flex: 1 1 auto;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem; }

.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: #f7f7f9;
  border-bottom: 1px solid rgba(21, 44, 31, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: #f7f7f9;
  border-top: 1px solid rgba(21, 44, 31, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-primary {
  background-color: #008c44;
  border-color: #008c44; }
  .card-primary .card-header,
  .card-primary .card-footer {
    background-color: transparent; }

.card-success {
  background-color: #008c44;
  border-color: #008c44; }
  .card-success .card-header,
  .card-success .card-footer {
    background-color: transparent; }

.card-info {
  background-color: #5bc0de;
  border-color: #5bc0de; }
  .card-info .card-header,
  .card-info .card-footer {
    background-color: transparent; }

.card-warning {
  background-color: #f0ad4e;
  border-color: #f0ad4e; }
  .card-warning .card-header,
  .card-warning .card-footer {
    background-color: transparent; }

.card-danger {
  background-color: #d9534f;
  border-color: #d9534f; }
  .card-danger .card-header,
  .card-danger .card-footer {
    background-color: transparent; }

.card-outline-primary {
  background-color: transparent;
  border-color: #008c44; }

.card-outline-secondary {
  background-color: transparent;
  border-color: #ccc; }

.card-outline-info {
  background-color: transparent;
  border-color: #5bc0de; }

.card-outline-success {
  background-color: transparent;
  border-color: #008c44; }

.card-outline-warning {
  background-color: transparent;
  border-color: #f0ad4e; }

.card-outline-danger {
  background-color: transparent;
  border-color: #d9534f; }

.card-inverse {
  color: rgba(255, 255, 255, 0.65); }
  .card-inverse .card-header,
  .card-inverse .card-footer {
    background-color: transparent;
    border-color: rgba(255, 255, 255, 0.2); }
  .card-inverse .card-header,
  .card-inverse .card-footer,
  .card-inverse .card-title,
  .card-inverse .card-blockquote {
    color: #fff; }
  .card-inverse .card-link,
  .card-inverse .card-text,
  .card-inverse .card-subtitle,
  .card-inverse .card-blockquote .blockquote-footer {
    color: rgba(255, 255, 255, 0.65); }
  .card-inverse .card-link:focus, .card-inverse .card-link:hover {
    color: #ffffff; }

.card-blockquote {
  padding: 0;
  margin-bottom: 0;
  border-left: 0; }

.card-img {
  border-radius: calc(0.25rem - 1px); }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap; }
    .card-deck .card {
      display: flex;
      flex: 1 0 0;
      flex-direction: column; }
      .card-deck .card:not(:first-child) {
        margin-left: 15px; }
      .card-deck .card:not(:last-child) {
        margin-right: 15px; } }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group .card {
      flex: 1 0 0; }
      .card-group .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group .card:first-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group .card:first-child .card-img-top {
          border-top-right-radius: 0; }
        .card-group .card:first-child .card-img-bottom {
          border-bottom-right-radius: 0; }
      .card-group .card:last-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group .card:last-child .card-img-top {
          border-top-left-radius: 0; }
        .card-group .card:last-child .card-img-bottom {
          border-bottom-left-radius: 0; }
      .card-group .card:not(:first-child):not(:last-child) {
        border-radius: 0; }
        .card-group .card:not(:first-child):not(:last-child) .card-img-top,
        .card-group .card:not(:first-child):not(:last-child) .card-img-bottom {
          border-radius: 0; } }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem; }
    .card-columns .card {
      display: inline-block;
      width: 100%;
      margin-bottom: 0.75rem; } }

.breadcrumb {
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #eceeef;
  border-radius: 0.25rem; }
  .breadcrumb::after {
    display: block;
    clear: both;
    content: ""; }

.breadcrumb-item {
  float: left; }
  .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    color: #636c72;
    content: "/"; }
  .breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: underline; }
  .breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: none; }
  .breadcrumb-item.active {
    color: #636c72; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 2;
  color: #ffffff;
  background-color: #008c44;
  border-color: #008c44; }

.page-item.disabled .page-link {
  color: #636c72;
  pointer-events: none;
  cursor: not-allowed;
  background-color: #ffffff;
  border-color: #ddd; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #008c44;
  background-color: #ffffff;
  border: 1px solid #ddd; }
  .page-link:focus, .page-link:hover {
    color: #00401f;
    text-decoration: none;
    background-color: #eceeef;
    border-color: #ddd; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: bold;
  line-height: 1;
  color: #ffffff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

a.badge:focus, a.badge:hover {
  color: #ffffff;
  text-decoration: none; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-default {
  background-color: #636c72; }
  .badge-default[href]:focus, .badge-default[href]:hover {
    background-color: #4b5257; }

.badge-primary {
  background-color: #008c44; }
  .badge-primary[href]:focus, .badge-primary[href]:hover {
    background-color: #00592b; }

.badge-success {
  background-color: #008c44; }
  .badge-success[href]:focus, .badge-success[href]:hover {
    background-color: #00592b; }

.badge-info {
  background-color: #5bc0de; }
  .badge-info[href]:focus, .badge-info[href]:hover {
    background-color: #31b0d5; }

.badge-warning {
  background-color: #f0ad4e; }
  .badge-warning[href]:focus, .badge-warning[href]:hover {
    background-color: #ec971f; }

.badge-danger {
  background-color: #d9534f; }
  .badge-danger[href]:focus, .badge-danger[href]:hover {
    background-color: #c9302c; }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #eceeef;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-hr {
  border-top-color: #d0d5d8; }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: bold; }

.alert-dismissible .close {
  position: relative;
  top: -0.75rem;
  right: -1.25rem;
  padding: 0.75rem 1.25rem;
  color: inherit; }

.alert-success {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #d0e9c6; }
  .alert-success hr {
    border-top-color: #c1e2b3; }
  .alert-success .alert-link {
    color: #2b542c; }

.alert-info {
  color: #31708f;
  background-color: #d9edf7;
  border-color: #bcdff1; }
  .alert-info hr {
    border-top-color: #a6d5ec; }
  .alert-info .alert-link {
    color: #245269; }

.alert-warning {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #faf2cc; }
  .alert-warning hr {
    border-top-color: #f7ecb5; }
  .alert-warning .alert-link {
    color: #66512c; }

.alert-danger {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebcccc; }
  .alert-danger hr {
    border-top-color: #e4b9b9; }
  .alert-danger .alert-link {
    color: #843534; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  overflow: hidden;
  font-size: 0.75rem;
  line-height: 1rem;
  text-align: center;
  background-color: #eceeef;
  border-radius: 0.25rem; }

.progress-bar {
  height: 1rem;
  line-height: 1rem;
  color: #ffffff;
  background-color: #008c44; }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite; }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #464a4c;
  text-align: inherit; }
  .list-group-item-action:focus, .list-group-item-action:hover {
    color: #464a4c;
    text-decoration: none;
    background-color: #f7f7f9; }
  .list-group-item-action:active {
    color: #152c1f;
    background-color: #eceeef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #ffffff;
  border: 1px solid rgba(21, 44, 31, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
  .list-group-item:focus, .list-group-item:hover {
    text-decoration: none; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #636c72;
    cursor: not-allowed;
    background-color: #ffffff; }
  .list-group-item.active {
    z-index: 2;
    color: #ffffff;
    background-color: #008c44;
    border-color: #008c44; }

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom: 0; }

.list-group-item-success {
  color: #3c763d;
  background-color: #dff0d8; }

a.list-group-item-success,
button.list-group-item-success {
  color: #3c763d; }
  a.list-group-item-success:focus, a.list-group-item-success:hover,
  button.list-group-item-success:focus,
  button.list-group-item-success:hover {
    color: #3c763d;
    background-color: #d0e9c6; }
  a.list-group-item-success.active,
  button.list-group-item-success.active {
    color: #fff;
    background-color: #3c763d;
    border-color: #3c763d; }

.list-group-item-info {
  color: #31708f;
  background-color: #d9edf7; }

a.list-group-item-info,
button.list-group-item-info {
  color: #31708f; }
  a.list-group-item-info:focus, a.list-group-item-info:hover,
  button.list-group-item-info:focus,
  button.list-group-item-info:hover {
    color: #31708f;
    background-color: #c4e3f3; }
  a.list-group-item-info.active,
  button.list-group-item-info.active {
    color: #fff;
    background-color: #31708f;
    border-color: #31708f; }

.list-group-item-warning {
  color: #8a6d3b;
  background-color: #fcf8e3; }

a.list-group-item-warning,
button.list-group-item-warning {
  color: #8a6d3b; }
  a.list-group-item-warning:focus, a.list-group-item-warning:hover,
  button.list-group-item-warning:focus,
  button.list-group-item-warning:hover {
    color: #8a6d3b;
    background-color: #faf2cc; }
  a.list-group-item-warning.active,
  button.list-group-item-warning.active {
    color: #fff;
    background-color: #8a6d3b;
    border-color: #8a6d3b; }

.list-group-item-danger {
  color: #a94442;
  background-color: #f2dede; }

a.list-group-item-danger,
button.list-group-item-danger {
  color: #a94442; }
  a.list-group-item-danger:focus, a.list-group-item-danger:hover,
  button.list-group-item-danger:focus,
  button.list-group-item-danger:hover {
    color: #a94442;
    background-color: #ebcccc; }
  a.list-group-item-danger.active,
  button.list-group-item-danger.active {
    color: #fff;
    background-color: #a94442;
    border-color: #a94442; }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1;
  color: #152c1f;
  text-shadow: 0 1px 0 #ffffff;
  opacity: .5; }
  .close:focus, .close:hover {
    color: #152c1f;
    text-decoration: none;
    opacity: .75; }

button.close {
  padding: 0;
  background: transparent;
  border: 0;
  -webkit-appearance: none; }

.modal-open {
  overflow: hidden; }

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -25%); }
  .modal.show .modal-dialog {
    transform: translate(0, 0); }

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 10px; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(21, 44, 31, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #152c1f; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  border-bottom: 1px solid #eceeef; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.4; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 15px; }

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 15px;
  border-top: 1px solid #eceeef; }
  .modal-footer > :not(:first-child) {
    margin-left: .25rem; }
  .modal-footer > :not(:last-child) {
    margin-right: .25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 30px auto; }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg {
    max-width: 800px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  font-family: "futura-pt", "Century Gothic", Verdana, sans-serif;
  font-style: normal;
  font-weight: normal;
  line-height: 1.4;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip.tooltip-top, .tooltip.bs-tether-element-attached-bottom {
    padding: 5px 0;
    margin-top: -3px; }
    .tooltip.tooltip-top .tooltip-inner::before, .tooltip.bs-tether-element-attached-bottom .tooltip-inner::before {
      bottom: 0;
      left: 50%;
      margin-left: -5px;
      content: "";
      border-width: 5px 5px 0;
      border-top-color: #152c1f; }
  .tooltip.tooltip-right, .tooltip.bs-tether-element-attached-left {
    padding: 0 5px;
    margin-left: 3px; }
    .tooltip.tooltip-right .tooltip-inner::before, .tooltip.bs-tether-element-attached-left .tooltip-inner::before {
      top: 50%;
      left: 0;
      margin-top: -5px;
      content: "";
      border-width: 5px 5px 5px 0;
      border-right-color: #152c1f; }
  .tooltip.tooltip-bottom, .tooltip.bs-tether-element-attached-top {
    padding: 5px 0;
    margin-top: 3px; }
    .tooltip.tooltip-bottom .tooltip-inner::before, .tooltip.bs-tether-element-attached-top .tooltip-inner::before {
      top: 0;
      left: 50%;
      margin-left: -5px;
      content: "";
      border-width: 0 5px 5px;
      border-bottom-color: #152c1f; }
  .tooltip.tooltip-left, .tooltip.bs-tether-element-attached-right {
    padding: 0 5px;
    margin-left: -3px; }
    .tooltip.tooltip-left .tooltip-inner::before, .tooltip.bs-tether-element-attached-right .tooltip-inner::before {
      top: 50%;
      right: 0;
      margin-top: -5px;
      content: "";
      border-width: 5px 0 5px 5px;
      border-left-color: #152c1f; }

.tooltip-inner {
  max-width: 200px;
  padding: 3px 8px;
  color: #ffffff;
  text-align: center;
  background-color: #152c1f;
  border-radius: 0.25rem; }
  .tooltip-inner::before {
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  padding: 1px;
  font-family: "futura-pt", "Century Gothic", Verdana, sans-serif;
  font-style: normal;
  font-weight: normal;
  line-height: 1.4;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(21, 44, 31, 0.2);
  border-radius: 0.3rem; }
  .popover.popover-top, .popover.bs-tether-element-attached-bottom {
    margin-top: -10px; }
    .popover.popover-top::before, .popover.popover-top::after, .popover.bs-tether-element-attached-bottom::before, .popover.bs-tether-element-attached-bottom::after {
      left: 50%;
      border-bottom-width: 0; }
    .popover.popover-top::before, .popover.bs-tether-element-attached-bottom::before {
      bottom: -11px;
      margin-left: -11px;
      border-top-color: rgba(21, 44, 31, 0.25); }
    .popover.popover-top::after, .popover.bs-tether-element-attached-bottom::after {
      bottom: -10px;
      margin-left: -10px;
      border-top-color: #ffffff; }
  .popover.popover-right, .popover.bs-tether-element-attached-left {
    margin-left: 10px; }
    .popover.popover-right::before, .popover.popover-right::after, .popover.bs-tether-element-attached-left::before, .popover.bs-tether-element-attached-left::after {
      top: 50%;
      border-left-width: 0; }
    .popover.popover-right::before, .popover.bs-tether-element-attached-left::before {
      left: -11px;
      margin-top: -11px;
      border-right-color: rgba(21, 44, 31, 0.25); }
    .popover.popover-right::after, .popover.bs-tether-element-attached-left::after {
      left: -10px;
      margin-top: -10px;
      border-right-color: #ffffff; }
  .popover.popover-bottom, .popover.bs-tether-element-attached-top {
    margin-top: 10px; }
    .popover.popover-bottom::before, .popover.popover-bottom::after, .popover.bs-tether-element-attached-top::before, .popover.bs-tether-element-attached-top::after {
      left: 50%;
      border-top-width: 0; }
    .popover.popover-bottom::before, .popover.bs-tether-element-attached-top::before {
      top: -11px;
      margin-left: -11px;
      border-bottom-color: rgba(21, 44, 31, 0.25); }
    .popover.popover-bottom::after, .popover.bs-tether-element-attached-top::after {
      top: -10px;
      margin-left: -10px;
      border-bottom-color: #f7f7f7; }
    .popover.popover-bottom .popover-title::before, .popover.bs-tether-element-attached-top .popover-title::before {
      position: absolute;
      top: 0;
      left: 50%;
      display: block;
      width: 20px;
      margin-left: -10px;
      content: "";
      border-bottom: 1px solid #f7f7f7; }
  .popover.popover-left, .popover.bs-tether-element-attached-right {
    margin-left: -10px; }
    .popover.popover-left::before, .popover.popover-left::after, .popover.bs-tether-element-attached-right::before, .popover.bs-tether-element-attached-right::after {
      top: 50%;
      border-right-width: 0; }
    .popover.popover-left::before, .popover.bs-tether-element-attached-right::before {
      right: -11px;
      margin-top: -11px;
      border-left-color: rgba(21, 44, 31, 0.25); }
    .popover.popover-left::after, .popover.bs-tether-element-attached-right::after {
      right: -10px;
      margin-top: -10px;
      border-left-color: #ffffff; }

.popover-title {
  padding: 8px 14px;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-title:empty {
    display: none; }

.popover-content {
  padding: 9px 14px; }

.popover::before,
.popover::after {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid; }

.popover::before {
  content: "";
  border-width: 11px; }

.popover::after {
  content: "";
  border-width: 10px; }

.carousel {
  position: relative; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }

.carousel-item {
  position: relative;
  display: none;
  width: 100%; }
  @media (-webkit-transform-3d) {
    .carousel-item {
      transition: transform 0.6s ease-in-out;
      backface-visibility: hidden;
      perspective: 1000px; } }
  @supports (transform: translate3d(0, 0, 0)) {
    .carousel-item {
      transition: transform 0.6s ease-in-out;
      backface-visibility: hidden;
      perspective: 1000px; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: flex; }

.carousel-item-next,
.carousel-item-prev {
  position: absolute;
  top: 0; }

@media (-webkit-transform-3d) {
  .carousel-item-next.carousel-item-left,
  .carousel-item-prev.carousel-item-right {
    transform: translate3d(0, 0, 0); }
  .carousel-item-next,
  .active.carousel-item-right {
    transform: translate3d(100%, 0, 0); }
  .carousel-item-prev,
  .active.carousel-item-left {
    transform: translate3d(-100%, 0, 0); } }

@supports (transform: translate3d(0, 0, 0)) {
  .carousel-item-next.carousel-item-left,
  .carousel-item-prev.carousel-item-right {
    transform: translate3d(0, 0, 0); }
  .carousel-item-next,
  .active.carousel-item-right {
    transform: translate3d(100%, 0, 0); }
  .carousel-item-prev,
  .active.carousel-item-left {
    transform: translate3d(-100%, 0, 0); } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #ffffff;
  text-align: center;
  opacity: 0.5; }
  .carousel-control-prev:focus, .carousel-control-prev:hover,
  .carousel-control-next:focus,
  .carousel-control-next:hover {
    color: #ffffff;
    text-decoration: none;
    outline: 0;
    opacity: .9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent no-repeat center center;
  background-size: 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23ffffff' viewBox='0 0 8 8'%3E%3Cpath d='M4 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23ffffff' viewBox='0 0 8 8'%3E%3Cpath d='M1.5 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 10px;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    position: relative;
    flex: 1 0 auto;
    max-width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    background-color: rgba(255, 255, 255, 0.5); }
    .carousel-indicators li::before {
      position: absolute;
      top: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 10px;
      content: ""; }
    .carousel-indicators li::after {
      position: absolute;
      bottom: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 10px;
      content: ""; }
  .carousel-indicators .active {
    background-color: #ffffff; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #ffffff;
  text-align: center; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-faded {
  background-color: #f7f7f7; }

.bg-primary {
  background-color: #008c44 !important; }

a.bg-primary:focus, a.bg-primary:hover {
  background-color: #00592b !important; }

.bg-success {
  background-color: #008c44 !important; }

a.bg-success:focus, a.bg-success:hover {
  background-color: #00592b !important; }

.bg-info {
  background-color: #5bc0de !important; }

a.bg-info:focus, a.bg-info:hover {
  background-color: #31b0d5 !important; }

.bg-warning {
  background-color: #f0ad4e !important; }

a.bg-warning:focus, a.bg-warning:hover {
  background-color: #ec971f !important; }

.bg-danger {
  background-color: #d9534f !important; }

a.bg-danger:focus, a.bg-danger:hover {
  background-color: #c9302c !important; }

.bg-inverse {
  background-color: #292b2c !important; }

a.bg-inverse:focus, a.bg-inverse:hover {
  background-color: #101112 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.rounded {
  border-radius: 0.25rem; }

.rounded-top {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem; }

.rounded-right {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.rounded-left {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.rounded-circle {
  border-radius: 50%; }

.rounded-0 {
  border-radius: 0; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 800px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

.d-print-block {
  display: none !important; }
  @media print {
    .d-print-block {
      display: block !important; } }

.d-print-inline {
  display: none !important; }
  @media print {
    .d-print-inline {
      display: inline !important; } }

.d-print-inline-block {
  display: none !important; }
  @media print {
    .d-print-inline-block {
      display: inline-block !important; } }

@media print {
  .d-print-none {
    display: none !important; } }

.order-first {
  order: -1; }

.order-last {
  order: 1; }

.order-0 {
  order: 0; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 1; }
  .order-sm-0 {
    order: 0; }
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 800px) {
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 1; }
  .order-md-0 {
    order: 0; }
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 1; }
  .order-lg-0 {
    order: 0; }
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 1; }
  .order-xl-0 {
    order: 0; }
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 800px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020; }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  clip-path: none; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.m-0 {
  margin: 0 !important; }

.mt-0 {
  margin-top: 0 !important; }

.mr-0 {
  margin-right: 0 !important; }

.mb-0 {
  margin-bottom: 0 !important; }

.ml-0 {
  margin-left: 0 !important; }

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important; }

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1 {
  margin-top: 0.25rem !important; }

.mr-1 {
  margin-right: 0.25rem !important; }

.mb-1 {
  margin-bottom: 0.25rem !important; }

.ml-1 {
  margin-left: 0.25rem !important; }

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important; }

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2 {
  margin-top: 0.5rem !important; }

.mr-2 {
  margin-right: 0.5rem !important; }

.mb-2 {
  margin-bottom: 0.5rem !important; }

.ml-2 {
  margin-left: 0.5rem !important; }

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important; }

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3 {
  margin-top: 1rem !important; }

.mr-3 {
  margin-right: 1rem !important; }

.mb-3 {
  margin-bottom: 1rem !important; }

.ml-3 {
  margin-left: 1rem !important; }

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important; }

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4 {
  margin-top: 1.5rem !important; }

.mr-4 {
  margin-right: 1.5rem !important; }

.mb-4 {
  margin-bottom: 1.5rem !important; }

.ml-4 {
  margin-left: 1.5rem !important; }

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important; }

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5 {
  margin-top: 3rem !important; }

.mr-5 {
  margin-right: 3rem !important; }

.mb-5 {
  margin-bottom: 3rem !important; }

.ml-5 {
  margin-left: 3rem !important; }

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important; }

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important; }

.m-6 {
  margin: 6rem !important; }

.mt-6 {
  margin-top: 6rem !important; }

.mr-6 {
  margin-right: 6rem !important; }

.mb-6 {
  margin-bottom: 6rem !important; }

.ml-6 {
  margin-left: 6rem !important; }

.mx-6 {
  margin-right: 6rem !important;
  margin-left: 6rem !important; }

.my-6 {
  margin-top: 6rem !important;
  margin-bottom: 6rem !important; }

.m-7 {
  margin: 9rem !important; }

.mt-7 {
  margin-top: 9rem !important; }

.mr-7 {
  margin-right: 9rem !important; }

.mb-7 {
  margin-bottom: 9rem !important; }

.ml-7 {
  margin-left: 9rem !important; }

.mx-7 {
  margin-right: 9rem !important;
  margin-left: 9rem !important; }

.my-7 {
  margin-top: 9rem !important;
  margin-bottom: 9rem !important; }

.m-8 {
  margin: 12rem !important; }

.mt-8 {
  margin-top: 12rem !important; }

.mr-8 {
  margin-right: 12rem !important; }

.mb-8 {
  margin-bottom: 12rem !important; }

.ml-8 {
  margin-left: 12rem !important; }

.mx-8 {
  margin-right: 12rem !important;
  margin-left: 12rem !important; }

.my-8 {
  margin-top: 12rem !important;
  margin-bottom: 12rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0 {
  padding-top: 0 !important; }

.pr-0 {
  padding-right: 0 !important; }

.pb-0 {
  padding-bottom: 0 !important; }

.pl-0 {
  padding-left: 0 !important; }

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important; }

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1 {
  padding-top: 0.25rem !important; }

.pr-1 {
  padding-right: 0.25rem !important; }

.pb-1 {
  padding-bottom: 0.25rem !important; }

.pl-1 {
  padding-left: 0.25rem !important; }

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important; }

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2 {
  padding-top: 0.5rem !important; }

.pr-2 {
  padding-right: 0.5rem !important; }

.pb-2 {
  padding-bottom: 0.5rem !important; }

.pl-2 {
  padding-left: 0.5rem !important; }

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important; }

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3 {
  padding-top: 1rem !important; }

.pr-3 {
  padding-right: 1rem !important; }

.pb-3 {
  padding-bottom: 1rem !important; }

.pl-3 {
  padding-left: 1rem !important; }

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important; }

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4 {
  padding-top: 1.5rem !important; }

.pr-4 {
  padding-right: 1.5rem !important; }

.pb-4 {
  padding-bottom: 1.5rem !important; }

.pl-4 {
  padding-left: 1.5rem !important; }

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important; }

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5 {
  padding-top: 3rem !important; }

.pr-5 {
  padding-right: 3rem !important; }

.pb-5 {
  padding-bottom: 3rem !important; }

.pl-5 {
  padding-left: 3rem !important; }

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important; }

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important; }

.p-6 {
  padding: 6rem !important; }

.pt-6 {
  padding-top: 6rem !important; }

.pr-6 {
  padding-right: 6rem !important; }

.pb-6 {
  padding-bottom: 6rem !important; }

.pl-6 {
  padding-left: 6rem !important; }

.px-6 {
  padding-right: 6rem !important;
  padding-left: 6rem !important; }

.py-6 {
  padding-top: 6rem !important;
  padding-bottom: 6rem !important; }

.p-7 {
  padding: 9rem !important; }

.pt-7 {
  padding-top: 9rem !important; }

.pr-7 {
  padding-right: 9rem !important; }

.pb-7 {
  padding-bottom: 9rem !important; }

.pl-7 {
  padding-left: 9rem !important; }

.px-7 {
  padding-right: 9rem !important;
  padding-left: 9rem !important; }

.py-7 {
  padding-top: 9rem !important;
  padding-bottom: 9rem !important; }

.p-8 {
  padding: 12rem !important; }

.pt-8 {
  padding-top: 12rem !important; }

.pr-8 {
  padding-right: 12rem !important; }

.pb-8 {
  padding-bottom: 12rem !important; }

.pl-8 {
  padding-left: 12rem !important; }

.px-8 {
  padding-right: 12rem !important;
  padding-left: 12rem !important; }

.py-8 {
  padding-top: 12rem !important;
  padding-bottom: 12rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto {
  margin-top: auto !important; }

.mr-auto {
  margin-right: auto !important; }

.mb-auto {
  margin-bottom: auto !important; }

.ml-auto {
  margin-left: auto !important; }

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important; }

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0 {
    margin-left: 0 !important; }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1 {
    margin-left: 0.25rem !important; }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2 {
    margin-left: 0.5rem !important; }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3 {
    margin-left: 1rem !important; }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4 {
    margin-left: 1.5rem !important; }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5 {
    margin-left: 3rem !important; }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .m-sm-6 {
    margin: 6rem !important; }
  .mt-sm-6 {
    margin-top: 6rem !important; }
  .mr-sm-6 {
    margin-right: 6rem !important; }
  .mb-sm-6 {
    margin-bottom: 6rem !important; }
  .ml-sm-6 {
    margin-left: 6rem !important; }
  .mx-sm-6 {
    margin-right: 6rem !important;
    margin-left: 6rem !important; }
  .my-sm-6 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important; }
  .m-sm-7 {
    margin: 9rem !important; }
  .mt-sm-7 {
    margin-top: 9rem !important; }
  .mr-sm-7 {
    margin-right: 9rem !important; }
  .mb-sm-7 {
    margin-bottom: 9rem !important; }
  .ml-sm-7 {
    margin-left: 9rem !important; }
  .mx-sm-7 {
    margin-right: 9rem !important;
    margin-left: 9rem !important; }
  .my-sm-7 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important; }
  .m-sm-8 {
    margin: 12rem !important; }
  .mt-sm-8 {
    margin-top: 12rem !important; }
  .mr-sm-8 {
    margin-right: 12rem !important; }
  .mb-sm-8 {
    margin-bottom: 12rem !important; }
  .ml-sm-8 {
    margin-left: 12rem !important; }
  .mx-sm-8 {
    margin-right: 12rem !important;
    margin-left: 12rem !important; }
  .my-sm-8 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0 {
    padding-left: 0 !important; }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1 {
    padding-left: 0.25rem !important; }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2 {
    padding-left: 0.5rem !important; }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3 {
    padding-left: 1rem !important; }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4 {
    padding-left: 1.5rem !important; }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5 {
    padding-left: 3rem !important; }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .p-sm-6 {
    padding: 6rem !important; }
  .pt-sm-6 {
    padding-top: 6rem !important; }
  .pr-sm-6 {
    padding-right: 6rem !important; }
  .pb-sm-6 {
    padding-bottom: 6rem !important; }
  .pl-sm-6 {
    padding-left: 6rem !important; }
  .px-sm-6 {
    padding-right: 6rem !important;
    padding-left: 6rem !important; }
  .py-sm-6 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important; }
  .p-sm-7 {
    padding: 9rem !important; }
  .pt-sm-7 {
    padding-top: 9rem !important; }
  .pr-sm-7 {
    padding-right: 9rem !important; }
  .pb-sm-7 {
    padding-bottom: 9rem !important; }
  .pl-sm-7 {
    padding-left: 9rem !important; }
  .px-sm-7 {
    padding-right: 9rem !important;
    padding-left: 9rem !important; }
  .py-sm-7 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important; }
  .p-sm-8 {
    padding: 12rem !important; }
  .pt-sm-8 {
    padding-top: 12rem !important; }
  .pr-sm-8 {
    padding-right: 12rem !important; }
  .pb-sm-8 {
    padding-bottom: 12rem !important; }
  .pl-sm-8 {
    padding-left: 12rem !important; }
  .px-sm-8 {
    padding-right: 12rem !important;
    padding-left: 12rem !important; }
  .py-sm-8 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto {
    margin-left: auto !important; }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; } }

@media (min-width: 800px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0 {
    margin-top: 0 !important; }
  .mr-md-0 {
    margin-right: 0 !important; }
  .mb-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0 {
    margin-left: 0 !important; }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1 {
    margin-left: 0.25rem !important; }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2 {
    margin-left: 0.5rem !important; }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3 {
    margin-left: 1rem !important; }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4 {
    margin-left: 1.5rem !important; }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5 {
    margin-left: 3rem !important; }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .m-md-6 {
    margin: 6rem !important; }
  .mt-md-6 {
    margin-top: 6rem !important; }
  .mr-md-6 {
    margin-right: 6rem !important; }
  .mb-md-6 {
    margin-bottom: 6rem !important; }
  .ml-md-6 {
    margin-left: 6rem !important; }
  .mx-md-6 {
    margin-right: 6rem !important;
    margin-left: 6rem !important; }
  .my-md-6 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important; }
  .m-md-7 {
    margin: 9rem !important; }
  .mt-md-7 {
    margin-top: 9rem !important; }
  .mr-md-7 {
    margin-right: 9rem !important; }
  .mb-md-7 {
    margin-bottom: 9rem !important; }
  .ml-md-7 {
    margin-left: 9rem !important; }
  .mx-md-7 {
    margin-right: 9rem !important;
    margin-left: 9rem !important; }
  .my-md-7 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important; }
  .m-md-8 {
    margin: 12rem !important; }
  .mt-md-8 {
    margin-top: 12rem !important; }
  .mr-md-8 {
    margin-right: 12rem !important; }
  .mb-md-8 {
    margin-bottom: 12rem !important; }
  .ml-md-8 {
    margin-left: 12rem !important; }
  .mx-md-8 {
    margin-right: 12rem !important;
    margin-left: 12rem !important; }
  .my-md-8 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0 {
    padding-top: 0 !important; }
  .pr-md-0 {
    padding-right: 0 !important; }
  .pb-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0 {
    padding-left: 0 !important; }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1 {
    padding-left: 0.25rem !important; }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2 {
    padding-left: 0.5rem !important; }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3 {
    padding-left: 1rem !important; }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4 {
    padding-left: 1.5rem !important; }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5 {
    padding-left: 3rem !important; }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .p-md-6 {
    padding: 6rem !important; }
  .pt-md-6 {
    padding-top: 6rem !important; }
  .pr-md-6 {
    padding-right: 6rem !important; }
  .pb-md-6 {
    padding-bottom: 6rem !important; }
  .pl-md-6 {
    padding-left: 6rem !important; }
  .px-md-6 {
    padding-right: 6rem !important;
    padding-left: 6rem !important; }
  .py-md-6 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important; }
  .p-md-7 {
    padding: 9rem !important; }
  .pt-md-7 {
    padding-top: 9rem !important; }
  .pr-md-7 {
    padding-right: 9rem !important; }
  .pb-md-7 {
    padding-bottom: 9rem !important; }
  .pl-md-7 {
    padding-left: 9rem !important; }
  .px-md-7 {
    padding-right: 9rem !important;
    padding-left: 9rem !important; }
  .py-md-7 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important; }
  .p-md-8 {
    padding: 12rem !important; }
  .pt-md-8 {
    padding-top: 12rem !important; }
  .pr-md-8 {
    padding-right: 12rem !important; }
  .pb-md-8 {
    padding-bottom: 12rem !important; }
  .pl-md-8 {
    padding-left: 12rem !important; }
  .px-md-8 {
    padding-right: 12rem !important;
    padding-left: 12rem !important; }
  .py-md-8 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto {
    margin-top: auto !important; }
  .mr-md-auto {
    margin-right: auto !important; }
  .mb-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto {
    margin-left: auto !important; }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0 {
    margin-left: 0 !important; }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1 {
    margin-left: 0.25rem !important; }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2 {
    margin-left: 0.5rem !important; }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3 {
    margin-left: 1rem !important; }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4 {
    margin-left: 1.5rem !important; }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5 {
    margin-left: 3rem !important; }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .m-lg-6 {
    margin: 6rem !important; }
  .mt-lg-6 {
    margin-top: 6rem !important; }
  .mr-lg-6 {
    margin-right: 6rem !important; }
  .mb-lg-6 {
    margin-bottom: 6rem !important; }
  .ml-lg-6 {
    margin-left: 6rem !important; }
  .mx-lg-6 {
    margin-right: 6rem !important;
    margin-left: 6rem !important; }
  .my-lg-6 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important; }
  .m-lg-7 {
    margin: 9rem !important; }
  .mt-lg-7 {
    margin-top: 9rem !important; }
  .mr-lg-7 {
    margin-right: 9rem !important; }
  .mb-lg-7 {
    margin-bottom: 9rem !important; }
  .ml-lg-7 {
    margin-left: 9rem !important; }
  .mx-lg-7 {
    margin-right: 9rem !important;
    margin-left: 9rem !important; }
  .my-lg-7 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important; }
  .m-lg-8 {
    margin: 12rem !important; }
  .mt-lg-8 {
    margin-top: 12rem !important; }
  .mr-lg-8 {
    margin-right: 12rem !important; }
  .mb-lg-8 {
    margin-bottom: 12rem !important; }
  .ml-lg-8 {
    margin-left: 12rem !important; }
  .mx-lg-8 {
    margin-right: 12rem !important;
    margin-left: 12rem !important; }
  .my-lg-8 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0 {
    padding-left: 0 !important; }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1 {
    padding-left: 0.25rem !important; }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2 {
    padding-left: 0.5rem !important; }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3 {
    padding-left: 1rem !important; }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4 {
    padding-left: 1.5rem !important; }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5 {
    padding-left: 3rem !important; }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .p-lg-6 {
    padding: 6rem !important; }
  .pt-lg-6 {
    padding-top: 6rem !important; }
  .pr-lg-6 {
    padding-right: 6rem !important; }
  .pb-lg-6 {
    padding-bottom: 6rem !important; }
  .pl-lg-6 {
    padding-left: 6rem !important; }
  .px-lg-6 {
    padding-right: 6rem !important;
    padding-left: 6rem !important; }
  .py-lg-6 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important; }
  .p-lg-7 {
    padding: 9rem !important; }
  .pt-lg-7 {
    padding-top: 9rem !important; }
  .pr-lg-7 {
    padding-right: 9rem !important; }
  .pb-lg-7 {
    padding-bottom: 9rem !important; }
  .pl-lg-7 {
    padding-left: 9rem !important; }
  .px-lg-7 {
    padding-right: 9rem !important;
    padding-left: 9rem !important; }
  .py-lg-7 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important; }
  .p-lg-8 {
    padding: 12rem !important; }
  .pt-lg-8 {
    padding-top: 12rem !important; }
  .pr-lg-8 {
    padding-right: 12rem !important; }
  .pb-lg-8 {
    padding-bottom: 12rem !important; }
  .pl-lg-8 {
    padding-left: 12rem !important; }
  .px-lg-8 {
    padding-right: 12rem !important;
    padding-left: 12rem !important; }
  .py-lg-8 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto {
    margin-left: auto !important; }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0 {
    margin-left: 0 !important; }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1 {
    margin-left: 0.25rem !important; }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2 {
    margin-left: 0.5rem !important; }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3 {
    margin-left: 1rem !important; }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4 {
    margin-left: 1.5rem !important; }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5 {
    margin-left: 3rem !important; }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .m-xl-6 {
    margin: 6rem !important; }
  .mt-xl-6 {
    margin-top: 6rem !important; }
  .mr-xl-6 {
    margin-right: 6rem !important; }
  .mb-xl-6 {
    margin-bottom: 6rem !important; }
  .ml-xl-6 {
    margin-left: 6rem !important; }
  .mx-xl-6 {
    margin-right: 6rem !important;
    margin-left: 6rem !important; }
  .my-xl-6 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important; }
  .m-xl-7 {
    margin: 9rem !important; }
  .mt-xl-7 {
    margin-top: 9rem !important; }
  .mr-xl-7 {
    margin-right: 9rem !important; }
  .mb-xl-7 {
    margin-bottom: 9rem !important; }
  .ml-xl-7 {
    margin-left: 9rem !important; }
  .mx-xl-7 {
    margin-right: 9rem !important;
    margin-left: 9rem !important; }
  .my-xl-7 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important; }
  .m-xl-8 {
    margin: 12rem !important; }
  .mt-xl-8 {
    margin-top: 12rem !important; }
  .mr-xl-8 {
    margin-right: 12rem !important; }
  .mb-xl-8 {
    margin-bottom: 12rem !important; }
  .ml-xl-8 {
    margin-left: 12rem !important; }
  .mx-xl-8 {
    margin-right: 12rem !important;
    margin-left: 12rem !important; }
  .my-xl-8 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0 {
    padding-left: 0 !important; }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1 {
    padding-left: 0.25rem !important; }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2 {
    padding-left: 0.5rem !important; }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3 {
    padding-left: 1rem !important; }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4 {
    padding-left: 1.5rem !important; }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5 {
    padding-left: 3rem !important; }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .p-xl-6 {
    padding: 6rem !important; }
  .pt-xl-6 {
    padding-top: 6rem !important; }
  .pr-xl-6 {
    padding-right: 6rem !important; }
  .pb-xl-6 {
    padding-bottom: 6rem !important; }
  .pl-xl-6 {
    padding-left: 6rem !important; }
  .px-xl-6 {
    padding-right: 6rem !important;
    padding-left: 6rem !important; }
  .py-xl-6 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important; }
  .p-xl-7 {
    padding: 9rem !important; }
  .pt-xl-7 {
    padding-top: 9rem !important; }
  .pr-xl-7 {
    padding-right: 9rem !important; }
  .pb-xl-7 {
    padding-bottom: 9rem !important; }
  .pl-xl-7 {
    padding-left: 9rem !important; }
  .px-xl-7 {
    padding-right: 9rem !important;
    padding-left: 9rem !important; }
  .py-xl-7 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important; }
  .p-xl-8 {
    padding: 12rem !important; }
  .pt-xl-8 {
    padding-top: 12rem !important; }
  .pr-xl-8 {
    padding-right: 12rem !important; }
  .pb-xl-8 {
    padding-bottom: 12rem !important; }
  .pl-xl-8 {
    padding-left: 12rem !important; }
  .px-xl-8 {
    padding-right: 12rem !important;
    padding-left: 12rem !important; }
  .py-xl-8 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto {
    margin-left: auto !important; }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; } }

.text-justify {
  text-align: justify !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 800px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-normal {
  font-weight: normal; }

.font-weight-bold {
  font-weight: bold; }

.font-italic {
  font-style: italic; }

.text-white {
  color: #fff !important; }

.text-muted {
  color: #636c72 !important; }

a.text-muted:focus, a.text-muted:hover {
  color: #4b5257 !important; }

.text-primary {
  color: #008c44 !important; }

a.text-primary:focus, a.text-primary:hover {
  color: #00592b !important; }

.text-success {
  color: #008c44 !important; }

a.text-success:focus, a.text-success:hover {
  color: #00592b !important; }

.text-info {
  color: #5bc0de !important; }

a.text-info:focus, a.text-info:hover {
  color: #31b0d5 !important; }

.text-warning {
  color: #f0ad4e !important; }

a.text-warning:focus, a.text-warning:hover {
  color: #ec971f !important; }

.text-danger {
  color: #d9534f !important; }

a.text-danger:focus, a.text-danger:hover {
  color: #c9302c !important; }

.text-gray-dark {
  color: #292b2c !important; }

a.text-gray-dark:focus, a.text-gray-dark:hover {
  color: #101112 !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

/* html5 elements ***************************************************** */
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden; }

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  -webkit-font-smoothing: initial;
  -moz-osx-font-smoothing: initial; }
  .h1 + p, .h2 + p, .h3 + p, .h4 + p, .h5 + p, .h6 + p, h1 + p, h2 + p, h3 + p, h4 + p, h5 + p, h6 + p {
    margin-top: 1em; }

@media (min-width: 992px) {
  h1 {
    font-size: 52px; }
  h2 {
    font-size: 46px; } }

/* bootstrap classes **************************************************** */
.row {
  min-width: 100%; }

.btn {
  border-width: 1px;
  border-radius: .25rem;
  align-self: left;
  min-width: 200px; }
  .bg-green .btn.btn-primary, .bg-green .btn.btn-default, .btn.btn-outline-primary, .btn.btn-outline-secondary {
    border-width: 3px; }
  .btn:hover {
    color: inherit; }
  .input-group .btn {
    min-width: 0; }

.embed-responsive {
  max-width: 100%;
  width: auto;
  height: auto; }

.footer {
  background: #152c1f;
  color: #ffffff;
  padding-top: 1.0rem;
  padding-bottom: 1.0rem; }
  .footer form {
    margin-bottom: 1rem; }
  .footer .form-control {
    padding: .4rem .75rem; }
  .footer .btn {
    background: #ff9e16;
    color: #ffffff;
    border-color: #152c1f; }

.card {
  border-radius: 0px;
  border: 1px solid #008c44; }
  .card .card-header a {
    color: inherit;
    display: block; }
  .card .card-header [data-toggle=collapse]:before {
    /*content: '\2303';
				font-family: sans-serif;
				text-align: center; */
    content: '';
    background-image: url(/img/icons/SVG/chevron-up.svg);
    background-size: 70% 70%;
    background-repeat: no-repeat;
    background-position: 50% 20%;
    float: right;
    border: 3px solid #ffffff;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    padding: 4px;
    font-size: 1.5rem;
    margin-left: 1rem;
    padding: 0.1em;
    width: 1.3em;
    height: 1.3em;
    transition: all 0.3s ease; }
  .card .card-header [data-toggle=collapse].collapsed:before {
    transform: rotate(180deg); }

.navbar .navbar-toggler {
  border: 0;
  padding: 0; }
  .navbar .navbar-toggler .navbar-toggler-icon {
    padding: 25px;
    background-image: url("/img/Buttons/Close.svg"); }
  .navbar .navbar-toggler.collapsed .navbar-toggler-icon {
    background-image: url("/img/Buttons/Hamburger.svg"); }

.navbar .navbar-brand img {
  height: 50px;
  width: auto; }

.navbar .navbar-collapse {
  margin-left: -1rem;
  margin-right: -1rem;
  padding-left: 1rem;
  padding-right: 1rem; }

.navbar .navbar-nav {
  padding-bottom: 1rem; }
  .navbar .navbar-nav .nav-link {
    color: #008c44; }
    @media (max-width: 991px) {
      .navbar .navbar-nav .nav-link {
        font-size: 1.5rem; } }

.form-control {
  border-color: #152c1f; }

/* custom classes ***************************************************** */
.large-logo img {
  width: 300px;
  height: auto; }

.fixed-top + .bg-hero.bg-hero-half {
  padding-top: 77px; }

.weight-semibold {
  font-weight: 500 !important; }

.weight-normal {
  font-weight: 400 !important; }

.bg-size-cover {
  background-size: cover;
  background-position: center center; }

.bg-size-contain {
  background-size: contain; }

.btn-default {
  background-color: #008c44;
  border-color: #ffffff;
  color: #ffffff; }

.navbar.bg-faded {
  background-color: rgba(255, 255, 255, 0.92);
  transition: background-color 0.5s ease; }
  .navbar.bg-faded .navbar-brand {
    transition: opacity 0.5s ease;
    opacity: 1; }
  @media (min-width: 992px) {
    .navbar.bg-faded.above-hero {
      background-color: rgba(247, 247, 247, 0); } }
  @media (max-width: 991px) {
    .navbar.bg-faded.above-hero {
      background-color: rgba(247, 247, 247, 0); }
      .navbar.bg-faded.above-hero div#header-nav {
        background-color: rgba(255, 255, 255, 0.92); } }
  @media (max-width: 991px) {
    .navbar.bg-faded.above-hero .navbar-brand {
      opacity: 0; } }
  .navbar.bg-faded.above-hero.above-hero-home .navbar-brand {
    opacity: 0; }

.bg-hero {
  background-position: center top;
  background-size: cover;
  min-height: 950px;
  height: 100vh;
  padding-top: 100px;
  padding-bottom: 200px; }
  .bg-hero.bg-hero-half {
    background-size: cover;
    background-position: center bottom;
    min-height: 17vw;
    height: auto;
    padding-top: 50px;
    padding-bottom: 100px;
    background-position: center 80%; }

.card-footer {
  background-color: transparent;
  border-width: 0; }
  .card-footer .btn {
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.4);
    margin-bottom: 50px;
    width: 200px;
    min-width: 0;
    max-width: 100%; }

.bg-gold, .bg-green, .bg-brown {
  color: #ffffff; }
  .bg-gold .h1, .bg-gold .h2, .bg-gold .h3, .bg-gold .h4, .bg-gold .h5, .bg-gold .h6, .bg-gold h1, .bg-gold h2, .bg-gold h3, .bg-gold h4, .bg-gold h5, .bg-gold h6, .bg-green .h1, .bg-green .h2, .bg-green .h3, .bg-green .h4, .bg-green .h5, .bg-green .h6, .bg-green h1, .bg-green h2, .bg-green h3, .bg-green h4, .bg-green h5, .bg-green h6, .bg-brown .h1, .bg-brown .h2, .bg-brown .h3, .bg-brown .h4, .bg-brown .h5, .bg-brown .h6, .bg-brown h1, .bg-brown h2, .bg-brown h3, .bg-brown h4, .bg-brown h5, .bg-brown h6 {
    color: inherit; }
  .bg-gold .card, .bg-gold .card-header, .bg-gold .card-block, .bg-gold .card-footer, .bg-green .card, .bg-green .card-header, .bg-green .card-block, .bg-green .card-footer, .bg-brown .card, .bg-brown .card-header, .bg-brown .card-block, .bg-brown .card-footer {
    background-color: transparent; }
  .bg-gold .card, .bg-green .card, .bg-brown .card {
    border-width: 0;
    border-top: 2px solid rgba(255, 255, 255, 0.5); }
    .bg-gold .card:first-child, .bg-green .card:first-child, .bg-brown .card:first-child {
      border-top: 0; }

.btn.close {
  background-image: url("/img/Buttons/Close.svg");
  font-size: 0;
  min-width: 0;
  border-radius: 50%;
  width: 44px;
  height: 44px;
  opacity: 1.0;
  position: absolute;
  right: 1.25rem;
  top: 1.25rem; }
  .btn.close + * {
    margin-top: 1rem; }

.bg-gold {
  background-color: #ff9e16 !important; }

.bg-green {
  background-color: #008c44 !important; }
  .bg-green a {
    color: #ffffff;
    text-decoration: underline; }

.bg-brown {
  background-color: #7a5035 !important; }

.color-gold {
  color: #ff9e16; }

.color-green {
  color: #008c44; }

.color-brown {
  color: #7a5035; }

.border-white {
  border: 1px solid #ffffff; }

.border-black {
  border: 1px solid #152c1f; }

.border-green {
  border: 1px solid #008c44; }

.border-brown {
  border: 1px solid #7a5035; }

.columns-two {
  padding-left: 0; }
  @media (min-width: 992px) {
    .columns-two {
      column-count: 2;
      column-gap: 1.25rem; } }
  .columns-two > li, .columns-two > dt, .columns-two > dd {
    margin-left: 1.25rem;
    break-inside: avoid; }
  .columns-two.list-unstyled > li, .columns-two.list-unstyled > dt, .columns-two.list-unstyled > dd {
    padding-bottom: 0.6rem; }

.callout {
  font-size: 18px;
  font-weight: 500; }
  @media (min-width: 992px) {
    .callout {
      font-size: 18px; } }
  .callout #accordion li:not(:last-of-type) {
    margin-bottom: 1rem; }

.callout-title {
  font-size: 35px;
  font-weight: 700; }
  @media (min-width: 992px) {
    .callout-title {
      font-size: 40px; } }

/* flex-borders ************************************************ */
.flex-borders {
  border: 1px solid #152c1f;
  border-right-width: 0;
  border-bottom-width: 0; }
  .flex-borders > * {
    border-style: inherit;
    border-color: inherit;
    border-width: 1px;
    border-top-width: 0;
    border-left-width: 0; }
  .flex-borders.border-white {
    border-color: #ffffff; }
  .flex-borders.border-green {
    border-color: #008c44; }
  .flex-borders.border-brown {
    border-color: #7a5035; }
  .flex-borders.border-gold {
    border-color: #ff9e16; }

/* -square and -notsquare classes *************************************** */
.col-12.square {
  min-height: 100vw; }

.col-6.square {
  min-height: 50vw; }

.col-3.square {
  min-height: 25vw; }

.col-12.xs-square {
  min-height: 100vw; }

.col-6.xs-square {
  min-height: 50vw; }

.col-3.xs-square {
  min-height: 25vw; }

.col-12.xs-notsquare,
.col-6.xs-notsquare,
.col-3.xs-notsquare {
  min-height: 0; }

.col-xs-12.xs-square {
  min-height: 100vw; }

.col-xs-6.xs-square {
  min-height: 50vw; }

.col-xs-3.xs-square {
  min-height: 25vw; }

.col-xs-6 .col-xs-6.xs-square {
  min-height: 25vw; }

.col-xs-12.xs-notsquare {
  min-height: 25vw; }

@media (min-width: 576px) {
  .col-12.sm-square {
    min-height: 100vw; }
  .col-6.sm-square {
    min-height: 50vw; }
  .col-3.sm-square {
    min-height: 25vw; }
  .col-12.sm-notsquare,
  .col-6.sm-notsquare,
  .col-3.sm-notsquare {
    min-height: 0; }
  .col-sm-12.xs-square {
    min-height: 100vw; }
  .col-sm-6.xs-square {
    min-height: 50vw; }
  .col-sm-3.xs-square {
    min-height: 25vw; }
  .col-sm-6 .col-xs-6.xs-square {
    min-height: 25vw; }
  .col-xs-12.xs-notsquare {
    min-height: 25vw; }
  .col-sm-6 .col-sm-6.xs-square {
    min-height: 25vw; }
  .col-xs-12.sm-notsquare {
    min-height: 25vw; }
  .col-sm-12.sm-square {
    min-height: 100vw; }
  .col-sm-6.sm-square {
    min-height: 50vw; }
  .col-sm-3.sm-square {
    min-height: 25vw; }
  .col-sm-6 .col-xs-6.sm-square {
    min-height: 25vw; }
  .col-sm-12.xs-notsquare {
    min-height: 25vw; }
  .col-sm-6 .col-sm-6.sm-square {
    min-height: 25vw; }
  .col-sm-12.sm-notsquare {
    min-height: 25vw; } }

@media (min-width: 800px) {
  .col-12.md-square {
    min-height: 100vw; }
  .col-6.md-square {
    min-height: 50vw; }
  .col-3.md-square {
    min-height: 25vw; }
  .col-12.md-notsquare,
  .col-6.md-notsquare,
  .col-3.md-notsquare {
    min-height: 0; }
  .col-md-12.xs-square {
    min-height: 100vw; }
  .col-md-6.xs-square {
    min-height: 50vw; }
  .col-md-3.xs-square {
    min-height: 25vw; }
  .col-md-6 .col-xs-6.xs-square {
    min-height: 25vw; }
  .col-xs-12.xs-notsquare {
    min-height: 25vw; }
  .col-md-6 .col-sm-6.xs-square {
    min-height: 25vw; }
  .col-xs-12.sm-notsquare {
    min-height: 25vw; }
  .col-md-6 .col-md-6.xs-square {
    min-height: 25vw; }
  .col-xs-12.md-notsquare {
    min-height: 25vw; }
  .col-md-12.sm-square {
    min-height: 100vw; }
  .col-md-6.sm-square {
    min-height: 50vw; }
  .col-md-3.sm-square {
    min-height: 25vw; }
  .col-md-6 .col-xs-6.sm-square {
    min-height: 25vw; }
  .col-sm-12.xs-notsquare {
    min-height: 25vw; }
  .col-md-6 .col-sm-6.sm-square {
    min-height: 25vw; }
  .col-sm-12.sm-notsquare {
    min-height: 25vw; }
  .col-md-6 .col-md-6.sm-square {
    min-height: 25vw; }
  .col-sm-12.md-notsquare {
    min-height: 25vw; }
  .col-md-12.md-square {
    min-height: 100vw; }
  .col-md-6.md-square {
    min-height: 50vw; }
  .col-md-3.md-square {
    min-height: 25vw; }
  .col-md-6 .col-xs-6.md-square {
    min-height: 25vw; }
  .col-md-12.xs-notsquare {
    min-height: 25vw; }
  .col-md-6 .col-sm-6.md-square {
    min-height: 25vw; }
  .col-md-12.sm-notsquare {
    min-height: 25vw; }
  .col-md-6 .col-md-6.md-square {
    min-height: 25vw; }
  .col-md-12.md-notsquare {
    min-height: 25vw; } }

@media (min-width: 992px) {
  .col-12.lg-square {
    min-height: 100vw; }
  .col-6.lg-square {
    min-height: 50vw; }
  .col-3.lg-square {
    min-height: 25vw; }
  .col-12.lg-notsquare,
  .col-6.lg-notsquare,
  .col-3.lg-notsquare {
    min-height: 0; }
  .col-lg-12.xs-square {
    min-height: 100vw; }
  .col-lg-6.xs-square {
    min-height: 50vw; }
  .col-lg-3.xs-square {
    min-height: 25vw; }
  .col-lg-6 .col-xs-6.xs-square {
    min-height: 25vw; }
  .col-xs-12.xs-notsquare {
    min-height: 25vw; }
  .col-lg-6 .col-sm-6.xs-square {
    min-height: 25vw; }
  .col-xs-12.sm-notsquare {
    min-height: 25vw; }
  .col-lg-6 .col-md-6.xs-square {
    min-height: 25vw; }
  .col-xs-12.md-notsquare {
    min-height: 25vw; }
  .col-lg-6 .col-lg-6.xs-square {
    min-height: 25vw; }
  .col-xs-12.lg-notsquare {
    min-height: 25vw; }
  .col-lg-12.sm-square {
    min-height: 100vw; }
  .col-lg-6.sm-square {
    min-height: 50vw; }
  .col-lg-3.sm-square {
    min-height: 25vw; }
  .col-lg-6 .col-xs-6.sm-square {
    min-height: 25vw; }
  .col-sm-12.xs-notsquare {
    min-height: 25vw; }
  .col-lg-6 .col-sm-6.sm-square {
    min-height: 25vw; }
  .col-sm-12.sm-notsquare {
    min-height: 25vw; }
  .col-lg-6 .col-md-6.sm-square {
    min-height: 25vw; }
  .col-sm-12.md-notsquare {
    min-height: 25vw; }
  .col-lg-6 .col-lg-6.sm-square {
    min-height: 25vw; }
  .col-sm-12.lg-notsquare {
    min-height: 25vw; }
  .col-lg-12.md-square {
    min-height: 100vw; }
  .col-lg-6.md-square {
    min-height: 50vw; }
  .col-lg-3.md-square {
    min-height: 25vw; }
  .col-lg-6 .col-xs-6.md-square {
    min-height: 25vw; }
  .col-md-12.xs-notsquare {
    min-height: 25vw; }
  .col-lg-6 .col-sm-6.md-square {
    min-height: 25vw; }
  .col-md-12.sm-notsquare {
    min-height: 25vw; }
  .col-lg-6 .col-md-6.md-square {
    min-height: 25vw; }
  .col-md-12.md-notsquare {
    min-height: 25vw; }
  .col-lg-6 .col-lg-6.md-square {
    min-height: 25vw; }
  .col-md-12.lg-notsquare {
    min-height: 25vw; }
  .col-lg-12.lg-square {
    min-height: 100vw; }
  .col-lg-6.lg-square {
    min-height: 50vw; }
  .col-lg-3.lg-square {
    min-height: 25vw; }
  .col-lg-6 .col-xs-6.lg-square {
    min-height: 25vw; }
  .col-lg-12.xs-notsquare {
    min-height: 25vw; }
  .col-lg-6 .col-sm-6.lg-square {
    min-height: 25vw; }
  .col-lg-12.sm-notsquare {
    min-height: 25vw; }
  .col-lg-6 .col-md-6.lg-square {
    min-height: 25vw; }
  .col-lg-12.md-notsquare {
    min-height: 25vw; }
  .col-lg-6 .col-lg-6.lg-square {
    min-height: 25vw; }
  .col-lg-12.lg-notsquare {
    min-height: 25vw; } }

@media (min-width: 1200px) {
  .col-12.xl-square {
    min-height: 100vw; }
  .col-6.xl-square {
    min-height: 50vw; }
  .col-3.xl-square {
    min-height: 25vw; }
  .col-12.xl-notsquare,
  .col-6.xl-notsquare,
  .col-3.xl-notsquare {
    min-height: 0; }
  .col-xl-12.xs-square {
    min-height: 100vw; }
  .col-xl-6.xs-square {
    min-height: 50vw; }
  .col-xl-3.xs-square {
    min-height: 25vw; }
  .col-xl-6 .col-xs-6.xs-square {
    min-height: 25vw; }
  .col-xs-12.xs-notsquare {
    min-height: 25vw; }
  .col-xl-6 .col-sm-6.xs-square {
    min-height: 25vw; }
  .col-xs-12.sm-notsquare {
    min-height: 25vw; }
  .col-xl-6 .col-md-6.xs-square {
    min-height: 25vw; }
  .col-xs-12.md-notsquare {
    min-height: 25vw; }
  .col-xl-6 .col-lg-6.xs-square {
    min-height: 25vw; }
  .col-xs-12.lg-notsquare {
    min-height: 25vw; }
  .col-xl-6 .col-xl-6.xs-square {
    min-height: 25vw; }
  .col-xs-12.xl-notsquare {
    min-height: 25vw; }
  .col-xl-12.sm-square {
    min-height: 100vw; }
  .col-xl-6.sm-square {
    min-height: 50vw; }
  .col-xl-3.sm-square {
    min-height: 25vw; }
  .col-xl-6 .col-xs-6.sm-square {
    min-height: 25vw; }
  .col-sm-12.xs-notsquare {
    min-height: 25vw; }
  .col-xl-6 .col-sm-6.sm-square {
    min-height: 25vw; }
  .col-sm-12.sm-notsquare {
    min-height: 25vw; }
  .col-xl-6 .col-md-6.sm-square {
    min-height: 25vw; }
  .col-sm-12.md-notsquare {
    min-height: 25vw; }
  .col-xl-6 .col-lg-6.sm-square {
    min-height: 25vw; }
  .col-sm-12.lg-notsquare {
    min-height: 25vw; }
  .col-xl-6 .col-xl-6.sm-square {
    min-height: 25vw; }
  .col-sm-12.xl-notsquare {
    min-height: 25vw; }
  .col-xl-12.md-square {
    min-height: 100vw; }
  .col-xl-6.md-square {
    min-height: 50vw; }
  .col-xl-3.md-square {
    min-height: 25vw; }
  .col-xl-6 .col-xs-6.md-square {
    min-height: 25vw; }
  .col-md-12.xs-notsquare {
    min-height: 25vw; }
  .col-xl-6 .col-sm-6.md-square {
    min-height: 25vw; }
  .col-md-12.sm-notsquare {
    min-height: 25vw; }
  .col-xl-6 .col-md-6.md-square {
    min-height: 25vw; }
  .col-md-12.md-notsquare {
    min-height: 25vw; }
  .col-xl-6 .col-lg-6.md-square {
    min-height: 25vw; }
  .col-md-12.lg-notsquare {
    min-height: 25vw; }
  .col-xl-6 .col-xl-6.md-square {
    min-height: 25vw; }
  .col-md-12.xl-notsquare {
    min-height: 25vw; }
  .col-xl-12.lg-square {
    min-height: 100vw; }
  .col-xl-6.lg-square {
    min-height: 50vw; }
  .col-xl-3.lg-square {
    min-height: 25vw; }
  .col-xl-6 .col-xs-6.lg-square {
    min-height: 25vw; }
  .col-lg-12.xs-notsquare {
    min-height: 25vw; }
  .col-xl-6 .col-sm-6.lg-square {
    min-height: 25vw; }
  .col-lg-12.sm-notsquare {
    min-height: 25vw; }
  .col-xl-6 .col-md-6.lg-square {
    min-height: 25vw; }
  .col-lg-12.md-notsquare {
    min-height: 25vw; }
  .col-xl-6 .col-lg-6.lg-square {
    min-height: 25vw; }
  .col-lg-12.lg-notsquare {
    min-height: 25vw; }
  .col-xl-6 .col-xl-6.lg-square {
    min-height: 25vw; }
  .col-lg-12.xl-notsquare {
    min-height: 25vw; }
  .col-xl-12.xl-square {
    min-height: 100vw; }
  .col-xl-6.xl-square {
    min-height: 50vw; }
  .col-xl-3.xl-square {
    min-height: 25vw; }
  .col-xl-6 .col-xs-6.xl-square {
    min-height: 25vw; }
  .col-xl-12.xs-notsquare {
    min-height: 25vw; }
  .col-xl-6 .col-sm-6.xl-square {
    min-height: 25vw; }
  .col-xl-12.sm-notsquare {
    min-height: 25vw; }
  .col-xl-6 .col-md-6.xl-square {
    min-height: 25vw; }
  .col-xl-12.md-notsquare {
    min-height: 25vw; }
  .col-xl-6 .col-lg-6.xl-square {
    min-height: 25vw; }
  .col-xl-12.lg-notsquare {
    min-height: 25vw; }
  .col-xl-6 .col-xl-6.xl-square {
    min-height: 25vw; }
  .col-xl-12.xl-notsquare {
    min-height: 25vw; } }

/* Solutions */
.logos li {
  margin: 0 !important; }
  @media (max-width: 991px) {
    .logos li {
      flex-basis: 50%; } }
  @media (min-width: 992px) {
    .logos li {
      flex-basis: 20%; } }

.border-circle {
  border-radius: 50%;
  border: 4px solid #008c44; }
  .flipper .border-circle {
    width: 210px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto; }

#markets .circle-wrapper {
  padding: 1.5rem 1.5rem 0.5rem; }

.logo-wrapper {
  width: 90%;
  margin: 0 auto 1rem;
  height: 6rem;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center; }
  .logo-wrapper img {
    min-width: 1px;
    max-height: 100%;
    max-height: 100%;
    width: auto; }

#employment-highlights .callout img {
  margin-top: 1.75rem;
  max-width: 50%;
  height: auto; }

#employment-highlights .callout p {
  min-height: 3.5rem; }

/* .js  */
form .label .inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
  padding: 0;
  margin: 0; }
  form .label .inputfile ~ .textlabel {
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: calc(100% - 140px);
    overflow: hidden; }
  form .label .inputfile ~ .btn-small {
    margin: 0;
    line-height: 1.4;
    width: 120px;
    min-width: 0;
    cursor: pointer;
    border-width: 0; }
  form .label .inputfile:focus ~ label,
  form .label .inputfile ~ label:hover {
    outline: 1px dotted #000;
    outline: -webkit-focus-ring-color auto 5px; }

/* .no-js form {
	.label {
		.inputfile ~ * {
			display: none;
		}
	}
} */
/* News */
.news-summary {
  margin-bottom: 3.5rem; }
  .news-summary h3 + p {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem; }

.news-image {
  margin-bottom: 1rem;
  overflow: hidden;
  height: 50vw;
  max-height: 350px;
  position: relative; }
  .news-image > * {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: auto; }
  .news-image .cycle-wrapper, .news-image .cycle-slideshow {
    height: 100%; }

.news-category {
  font-size: 14px;
  font-weight: bold; }

.news-date {
  font-style: italic; }
  .news-date:after {
    content: " — "; }

.news-readmore {
  display: inline-block;
  margin-top: 0.5rem;
  text-transform: uppercase;
  font-weight: 500; }
  .news-readmore:after {
    content: " ›"; }

/* Contact */
iframe.google-map {
  max-width: 100%; }
  @media (max-width: 991px) {
    iframe.google-map {
      max-height: 300px; } }

.required:after {
  color: red;
  content: " *"; }

button[disabled] {
  font-style: italic; }
  button[disabled], button[disabled]:hover {
    color: #ffffff; }

.form-control:focus {
  border-color: #008c44; }

/* jQuery Cycle2 - /inc/cycle2.php */
.cycle-slideshow {
  position: relative; }

.cycle-slide.cycle-center-slide {
  left: 50% !important;
  top: 50% !important;
  transform: translateX(-50%) translateY(-50%); }

.cycle-item {
  display: none; }
  @media (max-width: 991px) {
    .cycle-item {
      max-height: 336px;
      width: 100%;
      overflow: hidden; }
      .cycle-item img {
        position: relative;
        height: 100%;
        width: auto;
        left: 50%;
        transform: translateX(-50%); } }
  @media (min-width: 992px) {
    .cycle-item {
      max-width: 100%; }
      .cycle-item img {
        min-width: 100%;
        height: auto; } }

.cycle-caption {
  text-align: center; }

.cycle-pager {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  z-index: 101;
  color: #ffffff;
  text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.6); }
  .cycle-pager span {
    width: 20px;
    display: inline-block;
    cursor: pointer; }
  .cycle-pager .cycle-pager-active {
    color: #008c44; }

.cycle-prev, .cycle-next {
  position: absolute;
  z-index: 102;
  top: 0;
  width: 65px;
  height: 100%;
  opacity: 0.3; }
  .cycle-prev:not(.disabled), .cycle-next:not(.disabled) {
    opacity: 1;
    cursor: pointer; }

.cycle-prev:after,
.cycle-next:after {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 45px;
  height: 45px; }

.cycle-prev {
  left: 0; }
  .cycle-prev:after {
    content: url("/img/Buttons/Left Arrow.svg"); }

.cycle-next {
  right: 0; }
  .cycle-next:after {
    content: url("/img/Buttons/Right Arrow.svg"); }

500 .flip-container {
  perspective: 1600px; }
  500 .flip-container .btn {
    cursor: pointer; }

.flip-container, .front, .back {
  width: 100%;
  height: 470px; }

/* flip speed goes here */
.flipper {
  transform-style: preserve-3d;
  position: relative;
  height: 100%;
  /* hide back of pane during swap */
  /* front pane, placed above back */
  /* back, initially hidden pane */ }
  .flipper .front, .flipper .back {
    transition: 0.6s;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.4);
    backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    overflow-x: hidden;
    overflow-y: scroll;
    background: #ffffff; }
    .flipper .front .card-title, .flipper .back .card-title {
      text-align: center; }
  .flipper .front {
    transform: perspective(1600px) rotateY(0deg);
    opacity: 1;
    z-index: 2; }
  .flipper .back {
    transform: perspective(1600px) rotateY(180deg);
    opacity: 0;
    z-index: 1; }
    .flipper .back p {
      font-size: 16px; }
  .flip-container.hover .flipper .front {
    transform: perspective(1600px) rotateY(-180deg);
    opacity: 0;
    z-index: 1; }
  .flip-container.hover .flipper .back {
    transform: perspective(1600px) rotateY(0deg);
    opacity: 1;
    z-index: 2; }

@media print {
  * {
    font-family: sans-serif !important;
    background: transparent !important;
    color: black !important;
    text-shadow: none !important;
    filter: none !important;
    -ms-filter: none !important; }
  /* Black prints faster: h5bp.com/s */
  *::first-letter {
    margin: 0; }
  a,
  a:visited {
    text-decoration: underline; }
  a[href]:after {
    content: " (" attr(href) ")"; }
  abbr[title]:after {
    content: " (" attr(title) ")"; }
  .ir a:after,
  a[href^="javascript:"]:after,
  a[href^="#"]:after {
    content: ""; }
  /* Don't show links for images, or javascript/internal links */
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  /* h5bp.com/t */
  tr,
  img {
    page-break-inside: avoid; }
  img {
    max-width: 100% !important; }
  @page {
    margin: 0.5cm; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  .col-12 {
    min-height: 0 !important;
    height: auto !important;
    flex: 0 0 100% !important;
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important;
    left: 0 !important;
    padding: 1rem !important;
    margin: 0 !important; }
  .collapse {
    display: block; }
  .collapse.navbar-collapse {
    display: none; }
  .bg-hero {
    height: auto;
    min-height: 0; }
  .flipper .back, .flipper .front {
    position: relative;
    display: block;
    transform: none; } }
