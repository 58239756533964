500/* Solutions flip cards */

/* entire container, keeps perspective */
.flip-container {
	perspective: 1600px;
	.btn {
		cursor: pointer;
	}
}

.flip-container, .front, .back {
	width: 100%;
	height: 470px;
	@include media-breakpoint-up(sm) { // tablet styles
	//	min-height: 65vw;
	}
	@include media-breakpoint-up(lg) { // desktop styles
	//	min-height: 35vw;
	}
}

/* flip speed goes here */
.flipper {
//	transition: 0.6s;
	transform-style: preserve-3d; // IE10-11 don't support this! Flip both the front and back separately instead.
	position: relative;
	height: 100%;

	/* hide back of pane during swap */
	.front, .back {
		transition: 0.6s;
		box-shadow: 1px 1px 4px rgba(0,0,0,0.4);
		backface-visibility: hidden;
		position: absolute;
		top: 0;
		left: 0;
		overflow-x: hidden;
		overflow-y: scroll;
		background: $white;
		.card-title {
			text-align: center;
		}
	}

	/* front pane, placed above back */
	.front {
		transform: perspective(1600px) rotateY(0deg);
		opacity: 1;
		z-index: 2;
	}

	/* back, initially hidden pane */
	.back {
		transform: perspective(1600px) rotateY(180deg);
		opacity: 0;
		z-index: 1;
		p {
			font-size: 16px;
		}
	}

	.flip-container.hover & {
//		transform: rotateY(180deg);
		.front{
			transform : perspective(1600px) rotateY(-180deg);
			opacity: 0;
			z-index: 1;
		}
		.back{
			transform : perspective(1600px) rotateY(0deg);
			opacity: 1;
			z-index: 2
		}
	}
}

