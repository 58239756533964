/* jQuery Cycle2 - /inc/cycle2.php */

.cycle-slideshow {
	position: relative;
}
.cycle-slide.cycle-center-slide {
	left: 50% !important;
	top: 50% !important;
	transform: translateX(-50%) translateY(-50%);
}
.cycle-item {
	display: none; // hide slides until the JavaScript plugin loads
	@include media-breakpoint-down(md) { // mobile styles
		max-height: 336px; //440px;
		width: 100%;
		overflow: hidden;
		img {
			position: relative;
			height: 100%;
			width: auto;
			left: 50%;
			transform: translateX(-50%);
		}
	}
	@include media-breakpoint-up(lg) { // desktop styles
		max-width: 100%;
		img {
			min-width: 100%; // instead of width, for desktop Safari
			height: auto;
		}
	}
}
.cycle-caption {
	text-align: center;
}
.cycle-pager {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	text-align: center;
	z-index: 101;
	color: $white;
	text-shadow: 0px 2px 3px rgba(0,0,0,0.6);
	span {
		width: 20px;
		display: inline-block;
		cursor: pointer;
	}
	.cycle-pager-active {
		color: $green;
	}
}

.cycle-prev, .cycle-next {
	position: absolute;
	z-index: 102;
	top: 0;
	width: 65px;
	height: 100%;
	opacity: 0.3;
	&:not(.disabled) {
		opacity: 1;
		cursor: pointer;
	}
}
.cycle-prev:after,
.cycle-next:after {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
	width: 45px;
	height: 45px;
}
.cycle-prev {
	left: 0;
	&:after {
		content: url('/img/Buttons/Left\ Arrow.svg');
	}
}
.cycle-next {
	right: 0;
	&:after {
		content: url('/img/Buttons/Right\ Arrow.svg');
	}
}
