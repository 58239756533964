#employment-highlights {
	.callout {
		img {
			margin-top: #{$line-height-base*$lead-font-size}; // offset by one line of text
			max-width: 50%;
			height: auto;
		}
		p {
			min-height: #{2*$line-height-base*$lead-font-size}; // keep the text boxes approximately the same height
		}
	}
}

// the below was copied more-or-less verbatim from PWS, and could probably use some customization
/* .js  */form {
	.label {
		.inputfile {
			width: 0.1px;
			height: 0.1px;
			opacity: 0;
			overflow: hidden;
			position: absolute;
			z-index: -1;
			padding: 0;
			margin: 0;
			~ .textlabel {
				white-space: nowrap;
				text-overflow: ellipsis;
				max-width: calc(100% - 140px);
				overflow: hidden;
			}
			~ .btn-small {
				margin: 0;
				line-height: $line-height-base;
				width: 120px;
				min-width: 0;
				cursor: pointer;
				border-width: 0;
			}
			&:focus ~ label,
			~ label:hover {
				outline: 1px dotted #000;
				outline: -webkit-focus-ring-color auto 5px;
			}
		}
	}
}
// modernizr is required for the following
/* .no-js form {
	.label {
		.inputfile ~ * {
			display: none;
		}
	}
} */