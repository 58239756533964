@media print {

// from html5boilerplate: https://designshack.net/articles/css/6-thinks-i-learned-about-print-stylesheets-from-html5-boilerplate/
    * {
    	font-family: sans-serif !important;
        background: transparent !important;
        color: black !important;
        text-shadow: none !important;
        filter: none !important;
        -ms-filter: none !important;
    } /* Black prints faster: h5bp.com/s */

    *::first-letter {
    	margin: 0; // fixes dropped init letter in Chrome
    }
    
    a,
    a:visited {
        text-decoration: underline;
    }
    a[href]:after {
        content: " (" attr(href) ")";
    }
    abbr[title]:after {
        content: " (" attr(title) ")";
    }
    .ir a:after,
    a[href^="javascript:"]:after,
    a[href^="#"]:after {
        content: "";
    } /* Don't show links for images, or javascript/internal links */
    
    pre,
    blockquote {
        border: 1px solid #999;
        page-break-inside: avoid;
    }
    thead {
        display: table-header-group;
    }
    /* h5bp.com/t */
    
    tr,
    img {
        page-break-inside: avoid;
    }
    img {
        max-width: 100% !important;
    }
    @page {
        margin: 0.5cm;
    }
    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3;
    }
    h2,
    h3 {
        page-break-after: avoid;
    }



// custom style changes ****************************************** */
	.col-12 {
		min-height: 0 !important;
		height: auto !important;
		flex: 0 0 100% !important;
		width: 100% !important;
		max-width: 100% !important;
		min-width: 100% !important;
		left: 0 !important;
		padding: 1rem !important;
		margin: 0 !important;
	}
    .collapse {
        display: block;
    }
    .collapse.navbar-collapse {
        display: none;
    }
	.bg-hero {
		height: auto;
		min-height: 0;
	}
	.flipper .back, .flipper .front {
		position: relative;
		display: block;
		transform: none;
	}
}