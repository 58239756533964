/* Solutions */
.logos li {
	margin: 0 !important;
	@include media-breakpoint-down(md) { // desktop styles
		flex-basis: 50%;
	}
	@include media-breakpoint-up(lg) { // desktop styles
		flex-basis: 20%;
	}
}
.border-circle {
	border-radius: 50%;
	border: 4px solid $green;
	.flipper & {
		//width: 300px;
		width: 210px;
		max-width: 100%;
		margin-left: auto;
		margin-right: auto;
	}
}
#markets .circle-wrapper {
	padding: 1.5rem 1.5rem 0.5rem;
}
.logo-wrapper {
    width: 90%;
    margin: 0 auto 1rem;
    height: 6rem;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
    	min-width: 1px; // IE11
    	max-height: 100%;
    	max-height: 100%;
    	width: auto;
    }
}