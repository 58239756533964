/* variables ***************************************************** */

$white: #ffffff;
$green: #008c44;
$brown: #7a5035;
$gold: #ff9e16;
$black: #152c1f;

/* bootstrap variable overrides ***************************************************** */
html {
	font-size: 18px; // bootstrap-4 uses rem-based font sizes
}
//$font-size-base: 18px;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 800px, 
  lg: 992px,
  xl: 1200px
); // nudge md out a little further

$spacer:   1rem !default;
$spacer-x: $spacer !default;
$spacer-y: $spacer !default;
$spacers: (
  0: 0,
  1: ($spacer-x * .25),
  2: ($spacer-x * .5),
  3: $spacer-x,
  4: ($spacer-x * 1.5),
  5: ($spacer-x * 3),
  6: ($spacer-x * 6),
  7: ($spacer-x * 9),
  8: ($spacer-x * 12)
); // add px-6

$text-color: $black;
$brand-primary: $green;
$font-family-sans-serif: 'futura-pt','Century Gothic',Verdana,sans-serif;
$font-size-small: 15px; // 1.2rem
$body-color: $black;
$line-height-base: 1.4;

$headings-font-family: inherit;
$headings-font-weight: 900; // bold
$headings-line-height: 1.1;
$headings-color: $green;

$font-size-h1: 46px;
$font-size-h2: 40px;
$font-size-h3: 32px;
$font-size-h4: 24px;

$btn-font-weight: 500;
$btn-default-color: $green;
$btn-default-bg: $white;
$btn-default-border: $green;
$btn-border-radius-base: 0.5em;

/* non-bootstrap custom variables ******************************* */
$font-size-h1-desktop: 52px;
$font-size-h2-desktop: 46px;

$font-size-callout: 18px; // 16px desktop? 
$font-size-callout-title: 35px;
$font-weight-callout: 500;
$font-weight-callout-title: 700;

$font-size-callout-desktop: 18px;
$font-size-callout-title-desktop: 40px;

$btn-border-width: 3px;

$nav-height: 77px;

/* imports ******************************************************* */

//@import "../../bootstrap/assets/stylesheets/bootstrap";
@import "../../bootstrap-4/scss/bootstrap";
@import "custom"; // _custom.scss

@import "solutions"; // _solutions.scss
@import "careers"; // _careers.scss
@import "news"; // _news.scss
@import "contact"; // _contact.scss

//@import "carousel"; // _carousel.scss
@import "cycle2"; // _cycle2.scss
@import "flip"; // _flip.scss
@import "print"; // _print.scss